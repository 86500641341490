.wrap-faqs .wrap-text-head-faqs {
  text-align: center;
  color: #fff;
  padding-top: 2.7894vw; }

.wrap-faqs .btn-show-faq {
  background: #fff !important;
  border: 1px solid #cc3333;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  color: #cc3333;
  text-align: center;
  font-size: 20px;
  float: right; }

.wrap-faqs .icon-show-more-faq {
  position: relative;
  top: -6px; }

.wrap-faqs .title-faq-list {
  font-size: calc(100vw * 16 / 1434);
  padding-bottom: 1.95258vw;
  padding-left: 15px;
  padding-right: 15px; }

.wrap-faqs .title-faq-child {
  font-size: calc(100vw * 16 / 1434);
  color: #cc3333;
  font-weight: bold; }

.wrap-faqs .row-faq {
  cursor: pointer;
  padding-top: 20px;
  padding-bottom: 20px; }

.wrap-faqs .col-9-faq {
  padding: 0 !important; }

.wrap-faqs .card {
  border: none; }
  .wrap-faqs .card .card-body {
    padding: 10px 0; }

.wrap-faqs .wrap-faq-child {
  border-bottom: 1px solid #e4e4e4;
  padding-left: 15px;
  padding-right: 15px; }
  .wrap-faqs .wrap-faq-child .desc-us-p {
    font-size: calc(100vw * 16 / 1434);
    word-break: break-word;
    white-space: pre-line; }

.wrap-faqs .title-page-faqs {
  font-size: 2.51046vw;
  color: #cc3333; }

@media (max-width: 768px) {
  .wrap-faqs .wrap-text-head-faqs {
    padding-top: 40px;
    padding-right: 20px;
    padding-left: 20px; }
    .wrap-faqs .wrap-text-head-faqs .title-page-faqs {
      font-size: 40px; }
  .wrap-faqs .container-faqs {
    padding-right: 20px;
    padding-left: 20px; }
  .wrap-faqs .title-faq-list {
    font-size: 16px;
    padding-bottom: 28px; }
  .wrap-faqs .title-faq-child {
    font-size: 16px; }
  .wrap-faqs .wrap-faq-child .desc-us-p {
    font-size: 16px; } }

@media (max-width: 480px) {
  .wrap-faqs .wrap-text-head-faqs {
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 10px; }
  .wrap-faqs .container-faqs {
    padding-left: 0;
    padding-right: 0; }
  .wrap-faqs .wrap-text-head-faqs .title-page-faqs {
    font-size: 30px; } }

@media (max-width: 425px) {
  .wrap-faqs .container-price .desc-contact-price {
    margin: 0 calc(100vw * 50 / 1434) calc(100vw * 60 / 1434) calc(100vw * 50 / 1434); } }
