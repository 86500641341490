@charset "UTF-8";
.wrap-event-info .wrap-event-name {
  margin: 20px 6vw;
  padding-left: 15px;
  color: #7d181a; }

.wrap-event-info .post-infor-row {
  color: #06284c;
  margin: 0;
  padding: 0; }

.wrap-event-info .post-time {
  color: black !important;
  font-weight: bold; }

.wrap-event-info .post-infor-detail {
  font-size: 1.6vw; }

.wrap-event-info .post-infor-header {
  color: #cc3333;
  font-size: 1.2vw; }

.wrap-event-info .post-price {
  font-size: 1.8vw; }

.wrap-event-info .post-infor-row:not(:last-child) {
  margin-bottom: 1px; }

.wrap-event-info .main-type-color {
  color: #06284c !important; }

.wrap-event-info .bolder-title {
  font-weight: bold;
  font-family: "Segoe UI", sans-serif;
  color: #cc3333; }

.wrap-event-info .row-event-info {
  display: block; }

.wrap-event-info .post-infor-header-cell {
  padding-right: 10px;
  width: 6.7vw; }
  .wrap-event-info .post-infor-header-cell:first-child {
    vertical-align: bottom; }

.wrap-event-info .detail-event-page {
  font-size: calc(100vw*36/1434);
  text-shadow: 2px 2px 5px #000; }

.wrap-event-info .event-name {
  font-size: calc(100vw*65/1434);
  text-shadow: 2px 2px 5px #000; }

.wrap-event-info .desc-event-info {
  text-align: left;
  word-break: break-word;
  white-space: pre-line;
  color: #767676;
  font-size: calc(100vw * 16 /1434); }

.wrap-event-info .image-head-event-info {
  width: 100%;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 450px; }

.wrap-event-info .wrap-text-head {
  text-align: center;
  color: #fff;
  padding-top: 130px;
  padding-bottom: 130px; }

.wrap-event-info .wrap-icon-1 {
  width: calc(100vw*40/1434);
  text-align: center;
  margin-right: calc(100vw*20/1434);
  margin-bottom: calc(100vw*20/1434); }
  .wrap-event-info .wrap-icon-1 .img-event-info {
    width: calc(100vw*25/1434); }

.wrap-event-info .location-event-info .wrap-icon-1 .img-event-info {
  width: calc(100vw*15/1434); }

.wrap-event-info .name-event-info {
  font-size: calc(100vw*22/1434);
  margin-bottom: calc(100vw*20/1434);
  font-weight: bold;
  text-transform: uppercase; }

.wrap-event-info .time-event-info .event-date-time {
  font-size: calc(100vw*16/1434); }

.wrap-event-info .location-event-info .event-location {
  font-size: calc(100vw*16/1434); }

.wrap-event-info .type-event-info .event-topic {
  font-size: calc(100vw*16/1434); }

.wrap-event-info .container-travel img {
  max-width: 100%;
  object-fit: contain; }

.wrap-event-info .row-share {
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 30px; }

.wrap-event-info .icon-schedule {
  width: 20px;
  height: 20px;
  margin-right: 5px; }

.wrap-event-info .btn-price {
  padding: 15px 40px !important;
  margin-bottom: 60px;
  float: left;
  font-weight: bold; }

.wrap-event-info .wrap-post-img {
  width: 100%;
  background-color: transparent;
  text-align: center; }
  .wrap-event-info .wrap-post-img .img-event-info-1 {
    min-height: 300px;
    max-width: 100%;
    box-shadow: 1px 2px 10px 0px #9c979c;
    margin-bottom: 20px; }

.wrap-event-info .wrap-relevant-events {
  overflow-y: auto;
  max-height: 400px; }

.wrap-event-info .title-new-event {
  color: #fff;
  font-weight: bold;
  padding: 15px 30px;
  background: #424141;
  font-size: calc(100vw*22/1434);
  background: #cc3333;
  text-shadow: 2px 2px 5px #000; }

.wrap-event-info .img-description {
  font-size: calc(100vw * 16 /1434);
  text-align: center;
  font-weight: bold;
  color: #888; }

.wrap-event-info .wrap-link-info {
  padding: 15px 30px;
  border-bottom: 1px solid #424141;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.wrap-event-info .listEventNews a {
  color: #424141; }

.wrap-event-info .listEventNews:hover {
  background: #ededed !important; }
  .wrap-event-info .listEventNews:hover a {
    color: #424141;
    text-decoration: none; }

.wrap-event-info .moreInfoEvent {
  position: sticky;
  top: 15px; }

.wrap-event-info .wrap-link-info {
  font-size: calc(100vw*16/1434); }

/*Ipad ngang(1024 x 768)*/
@media screen and (max-width: 1024px) {
  .wrap-event-info .bolder-title {
    font-size: 2vw; } }

/*Ipad dọc(768 x 1024)*/
@media screen and (max-width: 768px) {
  .wrap-event-info .bolder-title {
    font-size: 1.75rem; }
  .wrap-event-info .post-infor-header {
    font-size: 1.2rem; }
  .wrap-event-info .post-infor-detail {
    font-size: 1.4rem; }
  .wrap-event-info .post-price {
    font-size: 1.5rem; }
  .wrap-event-info .row-event-info {
    margin: 5px; }
  .wrap-event-info .post-infor-header-cell {
    width: 100px; }
  .wrap-event-info .wrap-event-name {
    margin: 20px 0; }
  .wrap-event-info .image-head-event-info {
    height: 100%; }
  .wrap-event-info .container-travel {
    padding-left: 0;
    padding-right: 0; }
    .wrap-event-info .container-travel .detail-event-page {
      font-size: 36px;
      text-shadow: 2px 2px 5px #000; }
    .wrap-event-info .container-travel .event-name {
      font-size: 65px;
      text-shadow: 2px 2px 5px #000; }
    .wrap-event-info .container-travel .wrap-relevant-events {
      max-height: 200px; }
    .wrap-event-info .container-travel .title-new-event {
      background: #cc3333;
      color: #fff;
      text-shadow: 2px 2px 5px #000;
      font-size: 22px; }
    .wrap-event-info .container-travel .wrap-link-info {
      font-size: 14px; }
  .wrap-event-info .name-event-info {
    font-size: 22px;
    margin-bottom: 20px; }
  .wrap-event-info .time-event-info .event-date-time {
    font-size: 14px; }
  .wrap-event-info .location-event-info .event-location {
    font-size: 14px; }
  .wrap-event-info .type-event-info .event-topic {
    font-size: 14px; }
  .wrap-event-info .desc-event-info {
    font-size: 14px; }
  .wrap-event-info .img-description {
    font-size: 14px; }
  .wrap-event-info .wrap-icon-1 {
    width: 40px;
    text-align: center;
    margin-right: 20px;
    margin-bottom: 20px; }
    .wrap-event-info .wrap-icon-1 .img-event-info {
      width: 25px; }
  .wrap-event-info .location-event-info .wrap-icon-1 .img-event-info {
    width: 15px; } }

/*Tablet nhỏ(480 x 640)*/
@media screen and (max-width: 480px) {
  .wrap-event-info .container-travel {
    padding-left: 0;
    padding-right: 0; } }

/*Iphone(480 x 640)*/
/*Smart phone nhỏ*/
