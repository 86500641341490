.wrap-term .desc-term-p {
  word-break: break-word;
  white-space: pre-line;
  font-size: calc(100vw * 16 / 1434); }

.wrap-term .image-head-term {
  width: 100%; }

.wrap-term .wrap-text-head-term {
  text-align: center;
  padding-top: 2.7894vw; }

.wrap-term .title-center {
  text-align: center;
  color: #c01e1e;
  font-weight: bold;
  margin-top: 60px;
  margin-bottom: 60px; }

.wrap-term .divListTerms {
  text-align: left;
  padding: 0px 15px 15px 15px; }
  .wrap-term .divListTerms .title-term {
    color: #444; }

.wrap-term .title-page-term {
  font-size: 2.51046vw;
  color: #cc3333; }

.wrap-term .divListTerms .title-term {
  font-size: calc(100vw * 30 / 1434); }

@media (max-width: 768px) {
  .wrap-term .title-page-term {
    font-size: 40px; }
  .wrap-term .wrap-text-head-term {
    padding-top: 48px;
    padding-bottom: 40px; }
  .wrap-term .divListTerms .title-term {
    font-size: 16px; }
  .wrap-term .desc-term-p {
    font-size: 16px; } }

@media (max-width: 480px) {
  .wrap-term .wrap-text-head-term {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 0; }
  .wrap-term .divListTerms .title-term {
    font-size: 18px; }
  .wrap-term .container-terms {
    padding-left: 15px;
    padding-right: 15px; }
  .wrap-term .divListTerms {
    padding-left: 0;
    padding-right: 0; }
  .wrap-term .title-page-term {
    font-size: 30px; } }
