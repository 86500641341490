@charset "UTF-8";
.wrap-tour-slide .carousel .control-prev.control-arrow {
  top: 50%; }

.wrap-tour-slide .carousel .control-prev.control-arrow:before {
  content: url("/images/play-button-24.png");
  border: none !important; }

.wrap-tour-slide .carousel .control-next.control-arrow {
  top: 50%; }

.wrap-tour-slide .carousel .control-next.control-arrow:before {
  content: url("/images/play-button-24.png");
  border: none !important; }

.wrap-tour-slide .carousel .thumb {
  width: calc(100vw*89/1434);
  height: calc(100vw*72/1434);
  margin-top: calc(100vw*10/1434);
  margin-bottom: calc(100vw*5/1434);
  margin-left: calc(100vw*5/1434);
  margin-right: calc(100vw*5/1434);
  box-shadow: 1px 1px 2px 0px #9c979c; }

.wrap-tour-slide .carousel .thumbs {
  margin-bottom: 0px; }

.wrap-tour-slide .carousel .thumbs-wrapper {
  width: 85%;
  margin: 0 auto; }

.wrap-tour-slide .carousel .thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

.wrap-tour-slide .control-prev {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH"; }

/*Ipad ngang(1024 x 768)*/
@media screen and (max-width: 1024px) {
  .container-group-text-slide {
    top: 50%;
    position: absolute;
    transform: translateY(-50%); }
    .container-group-text-slide .btn-more-img-slide {
      width: calc(100vw*0.13947); }
    .container-group-text-slide .carousel .control-prev.control-arrow {
      top: 17%; }
    .container-group-text-slide .carousel .control-next.control-arrow {
      top: 17%; } }

/*Ipad dọc(768 x 1024)*/
@media screen and (max-width: 768px) {
  .container-group-text-slide {
    top: 50%;
    position: absolute;
    transform: translateY(-50%); }
    .container-group-text-slide .btn-more-img-slide {
      width: 200px;
      padding: 10px 35px !important;
      border-color: #cc3333;
      border-radius: 5px !important;
      font-size: 16px !important; }
      .container-group-text-slide .btn-more-img-slide span {
        font-size: 16px; }
    .container-group-text-slide .btn-more-img-slide:hover {
      width: 220px !important; }
  .carousel .thumbs-wrapper {
    width: 70% !important;
    margin: 0 auto; } }

/*Tablet nhỏ(480 x 640)*/
@media screen and (max-width: 480px) {
  .HomePage .carousel .control-prev.control-arrow {
    top: 20%; }
  .HomePage .carousel .control-next.control-arrow {
    top: 20%; } }

@media screen and (max-width: 480px) and (min-width: 769px) and (max-width: 1024px) {
  .carousel .control-prev.control-arrow {
    top: 26%; }
  .carousel .control-next.control-arrow {
    top: 26%; } }

@media screen and (max-width: 480px) {
  .txt-img-slide {
    font-size: 30px;
    margin: 0 10px; }
  .carousel .thumb {
    width: 52px !important;
    height: 42px !important;
    margin-top: 5px !important;
    margin-bottom: 10px !important;
    margin-left: 5px !important;
    margin-right: 5px !important;
    box-shadow: 1px 1px 2px 0px #9c979c; }
  .carousel .thumbs-wrapper {
    width: 60% !important; } }

/*Iphone(480 x 640)*/
/*Smart phone nhỏ*/
