@charset "UTF-8";
.useful .wrap-usas {
  height: calc(100vw * 42 / 1434); }

.useful .reset-a-parent {
  color: black;
  text-decoration: none; }

.useful .image-head-event {
  height: 200px;
  width: 100%;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

.useful .title-usefulInfo {
  font-size: calc(100vw * 22 / 1434);
  margin-bottom: calc(100vw * 20 / 1434);
  font-weight: bold;
  text-transform: uppercase;
  color: #767676; }

.useful .wrap-list-event {
  padding-bottom: 60px; }

.useful .container-travel-event {
  position: relative;
  top: -60px; }

.useful .shadow-head {
  font-size: calc(100vw * 65 / 1434);
  text-shadow: 2px 2px 5px #000;
  text-transform: uppercase; }

.useful .detail-event {
  top: auto !important;
  bottom: -25px;
  color: #cc3333; }

.useful .location-event {
  font-weight: 500; }

.useful .desc-event {
  margin-bottom: 20px; }

.useful .title-usas {
  color: #c01e1e; }

.useful .hoan-select {
  width: 200px !important; }

.useful .row-3 {
  justify-content: center; }

.useful .row-3 {
  position: relative; }

.useful .dd-wrapper {
  position: absolute !important;
  right: 0;
  width: 100%; }
  .useful .dd-wrapper .dd-list {
    max-height: 500px;
    overflow-y: auto; }

.useful .dd-wrapper .dd-header {
  height: calc(100vw * 42 / 1434);
  border-radius: 0 !important; }

.useful .dd-header .fa {
  height: calc(100vw * 40 / 1434);
  width: calc(100vw * 50 / 1434);
  background: #cc3333;
  margin-right: 0 !important; }

.useful .fa-angle-up:after {
  content: url("/images/arrow-up.png");
  position: relative;
  top: 0px;
  left: 12px !important; }

.useful .fa-angle-down:after {
  content: url("/images/arrow-down.png");
  position: relative;
  top: 0px;
  left: 12px !important; }

.useful .wrap-img {
  height: auto; }

.useful .name-usa-title {
  line-height: 22px;
  font-size: 22px; }

.useful .row-info-usa {
  margin-top: 10px; }

.useful .info-more-usa {
  margin-right: 20px;
  color: #8e8c8c; }

.useful .event-item-img-date {
  width: 70px;
  height: 70px;
  font-size: 12px; }

.useful .dd-wrapper {
  position: relative !important; }

.useful .wrap-new-event-info a {
  color: #444; }

.useful .title-link-usa-full-info {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: calc(100vw * 16 / 1434);
  font-weight: bold; }

.useful .wrap-new-event-info a:hover {
  text-decoration: none;
  color: #cc3333; }

.useful .desc-link {
  font-size: calc(100vw * 14 / 1434);
  color: #676763;
  color: #676767;
  overflow: hidden;
  display: -webkit-box;
  margin-top: calc(100vw * 8 / 1434);
  line-height: calc(100vw * 18 / 1434);
  max-height: calc(100vw * 54 / 1434);
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; }

.useful .list-event-new {
  padding-left: 4px;
  padding-top: 5px;
  padding-bottom: 5px; }

.useful .image-head-use-full-link-info {
  width: 100%;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

.useful .wrap-text-head {
  text-align: center;
  color: #fff;
  padding-top: calc(100vw * 130 / 1434);
  padding-bottom: calc(100vw * 130 / 1434); }

.useful .btn-price-post {
  float: left;
  font-weight: bold; }
  .useful .btn-price-post .icon-arrow-btn {
    font-weight: bold; }

.useful .wrap-reference {
  width: 100%;
  text-align: right;
  font-size: calc(100vw * 16 / 1434); }

.useful .info-title {
  color: #767676;
  font-size: calc(100vw * 16 / 1434); }

.useful .wrap-post-img .img-event-info-1 {
  min-height: calc(100vw * 300 / 1434);
  max-width: 100%;
  box-shadow: 1px 2px 10px 0px #9c979c;
  margin-bottom: calc(100vw * 20 / 1434); }

.useful .img-post-link {
  object-fit: cover;
  height: calc(100vw * 100 / 1434); }

.useful .list-event-new:hover .row-list-reference {
  background: #ededed;
  box-shadow: 1px 2px 10px 0px #9c979c;
  cursor: pointer; }

.useful .title-center {
  margin-bottom: calc(100vw * 30 / 1434); }

.useful .content-reference {
  padding-right: calc(100vw * 15 / 1434);
  padding-left: calc(100vw * 15 / 1434); }

.useful .btn-see-more-usas .btn-contact {
  padding: calc(100vw * 0.02 * 0.28) calc(100vw * 0.02) !important;
  margin-top: calc(100vw * 40 / 1434); }
  .useful .btn-see-more-usas .btn-contact span {
    font-size: calc(100vw * 0.012) !important; }
  .useful .btn-see-more-usas .btn-contact i {
    font-size: calc(100vw * 0.02) !important; }

.useful .row-use-full-page {
  margin-bottom: calc(100vw * 30 / 1434); }

/*Ipad ngang(1024 x 768)*/
@media screen and (max-width: 1024px) {
  .useful .container-group-text-slide {
    top: 50%;
    position: absolute;
    transform: translateY(-50%); }
    .useful .container-group-text-slide .btn-more-img-slide {
      width: calc(100vw * 0.13947); }
  .useful .dd-header .fa {
    width: 40px; }
  .useful .dd-header .fa-angle-down:after {
    top: -5px;
    left: 8px !important; }
  .useful .dd-header .fa-angle-up:after {
    top: -5px;
    left: 8px !important; } }

/*Ipad dọc(768 x 1024)*/
@media screen and (max-width: 768px) {
  .container-group-text-slide {
    top: 50%;
    position: absolute;
    transform: translateY(-50%); }
    .container-group-text-slide .btn-more-img-slide {
      width: 200px;
      padding: 10px 35px !important;
      border-color: #cc3333;
      border-radius: 5px !important;
      font-size: 16px !important; }
      .container-group-text-slide .btn-more-img-slide span {
        font-size: 16px; }
    .container-group-text-slide .btn-more-img-slide:hover {
      width: 220px !important; }
  .useful .title-usefulInfo {
    font-size: 22px; }
  .useful .info-title {
    font-size: 14px; }
  .useful .wrap-reference {
    font-size: 14px; }
  .useful .dd-wrapper .dd-header {
    height: 32px; }
    .useful .dd-wrapper .dd-header .fa {
      height: 32px;
      width: 42px; }
    .useful .dd-wrapper .dd-header .fa-angle-down:after {
      top: -5px;
      left: 8px !important; }
    .useful .dd-wrapper .dd-header .fa-angle-up:after {
      top: -5px;
      left: 8px !important; }
  .useful .list-event-new {
    margin-top: 15px;
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;
    box-shadow: 0px 1px 5px 0px #9c979c; }
  .useful .img-post-link {
    height: 150px; }
  .useful .content-reference {
    padding: 10px; }
  .useful .title-link-usa-full-info {
    font-size: 16px; }
  .useful .desc-link {
    font-size: 14px;
    line-height: 18px;
    max-height: 54px;
    margin-top: 0; }
  .useful .UsefullNewEvent {
    margin-top: 38px !important; }
  .useful .new-col-usefull {
    margin-top: 15px; }
  .useful .wrap-new-event-info {
    margin-top: 38px; }
  .useful .btn-see-more-usas .btn-more-usbeauty {
    padding: 8px 30px !important; }
  .useful .dd-wrapper .dd-list {
    max-height: 215px; }
  .useful .wrap-text-head {
    padding-top: 130px;
    padding-bottom: 130px; }
    .useful .wrap-text-head .shadow-head {
      font-size: 65px; } }

/*Tablet nhỏ(480 x 640)*/
@media screen and (max-width: 480px) {
  .txt-img-slide {
    font-size: 30px;
    margin: 0 10px; }
  .useful .wrap-text-head {
    padding-top: 130px;
    padding-bottom: 130px; }
    .useful .wrap-text-head .shadow-head {
      font-size: 65px; }
  .useful .row-use-full-page {
    margin-bottom: 20px; }
  .useful .btn-see-more-usas .btn-contact span {
    font-size: 18px !important; } }

/*Iphone(480 x 640)*/
/*Smart phone nhỏ*/
