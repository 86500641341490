.wrap-about-us .desc-us-p {
  word-break: break-word;
  white-space: pre-line;
  margin-bottom: 15px; }

.wrap-about-us .wrap-text-head-aboutus {
  text-align: center;
  color: #fff;
  padding-top: 2.7894vw;
  padding-bottom: 1.95258vw; }

.wrap-about-us .title-center {
  text-align: center;
  color: #cc3333;
  margin-top: calc(100vw*30/1434);
  margin-bottom: calc(100vw*30/1434);
  font-size: 2.51046vw; }

.wrap-about-us .list-key-member {
  padding-left: 60px;
  padding-right: 60px; }
  .wrap-about-us .list-key-member .row {
    margin-bottom: 40px; }
  .wrap-about-us .list-key-member .img-key-mem {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 1px solid #fff;
    max-width: 100%; }
  .wrap-about-us .list-key-member .name-key-mem {
    font-size: calc(100vw*18/1434);
    font-weight: bold;
    margin-bottom: 10px; }
  .wrap-about-us .list-key-member .desc-key-mem {
    font-size: calc(100vw*16/1434); }

.wrap-about-us .desc-us-top {
  padding-left: 15px;
  padding-right: 15px;
  font-size: 1.3vw; }

.wrap-about-us .desc-us-bot {
  padding-left: 15px;
  padding-right: 15px;
  font-size: 1.3vw; }

.wrap-about-us .title-page-aboutus {
  color: #cc3333;
  font-size: 2.51046vw; }

@media (max-width: 768px) {
  .wrap-about-us .list-key-member {
    padding-left: unset;
    padding-right: unset; }
  .wrap-about-us .container-aboutus {
    padding-left: 20px;
    padding-right: 20px; }
  .wrap-about-us .title-page-aboutus {
    font-size: 30px; }
  .wrap-about-us .title-center {
    font-size: 20px; }
  .wrap-about-us .list-key-member .name-key-mem {
    font-size: 18px; }
  .wrap-about-us .list-key-member .desc-key-mem {
    font-size: 16px; }
  .wrap-about-us .desc-us-top {
    font-size: 16px; }
  .wrap-about-us .desc-us-bot {
    font-size: 16px; } }

@media (max-width: 480px) {
  .container-aboutus {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .wrap-about-us .wrap-text-head-aboutus {
    padding-top: 0;
    padding-bottom: 0; }
  .wrap-about-us .container-aboutus {
    padding-left: 15px;
    padding-right: 15px; } }
