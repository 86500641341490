@charset "UTF-8";
.wrap-event .reset-a-parent {
  color: black;
  text-decoration: none; }

.wrap-event .image-head-event {
  width: 100%;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

.wrap-event .event-header {
  text-align: center;
  padding-top: calc(100vw * 40 / 1434);
  padding-bottom: calc(100vw * 28 / 1434);
  color: #fff;
  padding-left: calc(100vw*105/1434);
  padding-right: calc(100vw*105/1434); }
  .wrap-event .event-header h1 {
    font-size: calc(100vw*36/1434); }

.wrap-event .wrap-list-event {
  padding-bottom: calc(100vw * 0.05); }

.wrap-event .btn-more-list-event-page {
  margin-top: calc(100vw*40/1434);
  padding: calc(100vw*0.006) calc(100vw*0.02) !important; }
  .wrap-event .btn-more-list-event-page span {
    font-size: calc(100vw * 0.0111);
    -webkit-transition: 0.5s;
    transition: 0.5s; }
  .wrap-event .btn-more-list-event-page .icon-arrow-btn {
    font-size: calc(100vw * 0.02);
    -webkit-transition: 0.5s;
    transition: 0.5s; }

.wrap-event .line-event {
  width: 100%;
  padding-left: 8px;
  margin-bottom: 5px; }

.wrap-event .wrap-list-event .col-3-event {
  padding-right: calc(100vw*4/1434);
  padding-left: calc(100vw*4/1434);
  margin-bottom: calc(100vw*4/1434); }

.wrap-event .container-travel-event {
  position: relative;
  top: -60px; }

.wrap-event .shadow-head {
  text-shadow: 2px 2px 5px #000; }

.wrap-event .detail-event {
  font-size: calc(100vw* 24 /1434) !important;
  transition: all 0.2s ease;
  opacity: 0;
  color: #cc3333;
  position: absolute;
  top: calc(100vw* -9/1434);
  right: calc(100vw*15/1434);
  -webkit-transition: 0.5s;
  transition: 0.5s; }

.wrap-event .location-event {
  font-weight: 500; }

.wrap-event .desc-event {
  margin-bottom: 20px; }

.wrap-event .wrap-img {
  height: 196px; }

.wrap-event .wrap-img .img-event {
  height: 100%;
  object-fit: cover; }

.wrap-event .wrap-info-event .category-event {
  font-size: .95vw;
  color: #cc3333;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: calc(100vw*10/1434); }

.wrap-event .wrap-info-event .name-event {
  font-size: calc(100vw*24/1434);
  color: #06284c;
  line-height: calc(100vw*30/1434);
  margin-bottom: .2vw; }

.wrap-event .wrap-info-event .location-event {
  margin-left: calc(100vw* -15/1434);
  color: #cc3333;
  font-size: 1.2vw;
  font-style: italic;
  padding: 0 calc(100vw*15/1434);
  margin-top: 0px;
  margin-bottom: .7vw; }

.wrap-event .wrap-info-event .desc-event {
  color: #676767;
  font-size: 1.3vw;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 1.7vw;
  max-height: 10vw;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical; }

.wrap-event .wrap-info-event .arrowEvent {
  color: #cc3333;
  font-size: calc(100vw*15/1434); }

.wrap-event .row-info-event {
  height: calc(100vw*320/1434); }

.wrap-event .wrap-img {
  position: relative;
  width: 100% !important;
  height: 100% !important; }

.wrap-event .wrap-img:after {
  content: "";
  display: block;
  padding-bottom: 100%;
  width: 100%; }

.wrap-event .content-xyz {
  position: absolute;
  width: 100%;
  height: 100%; }

.wrap-event .cont-event {
  position: unset;
  top: unset; }

.wrap-event .infoEvent {
  background: #ededed; }

.wrap-event .cate-event {
  font-size: 12px; }

.wrap-event .nameEvent {
  font-size: 16px; }

.wrap-event .locat-event {
  font-size: 15px; }

.wrap-event .descEvent {
  font-size: 14px; }

.wrap-event .moreEvent {
  font-size: 15px; }

/*Ipad ngang(1024 x 768)*/
@media screen and (max-width: 1024px) {
  .btn-more-list-event-page {
    margin-top: 45px !important; }
  .wrap-info-event .detail-event {
    font-size: calc(100vw* 30 /1434) !important;
    top: calc(100vw* -6/1434); } }

/*Ipad dọc(768 x 1024)*/
@media screen and (max-width: 768px) {
  .event-header {
    padding-top: 40px !important;
    padding-bottom: 28px !important;
    padding-left: 20px !important;
    padding-right: 20px !important; }
    .event-header h1 {
      font-size: 40px !important; }
  .wrap-list-event {
    padding-bottom: 0px !important; }
    .wrap-list-event .container-travel-event {
      padding-left: 4px;
      padding-right: 4px; }
      .wrap-list-event .container-travel-event .col-3-event {
        padding-left: 4px;
        padding-right: 4px;
        margin-bottom: 4px; }
      .wrap-list-event .container-travel-event .btn-more-list-event-page {
        margin-top: 40px;
        margin-bottom: 48px;
        padding: 10px 35px !important; }
        .wrap-list-event .container-travel-event .btn-more-list-event-page span {
          font-size: 16px; }
        .wrap-list-event .container-travel-event .btn-more-list-event-page .icon-arrow-btn {
          font-size: 30px; }
      .wrap-list-event .container-travel-event .btn-more-list-event-page:hover {
        width: 184px !important; }
  .wrap-info-event .category-event {
    font-size: 12px !important;
    color: #cc3333;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 10px !important; }
  .wrap-info-event .name-event {
    font-size: 24px !important;
    color: #06284c;
    line-height: 30px !important;
    margin-bottom: 10px !important; }
  .wrap-info-event .location-event {
    margin-left: -15px !important;
    color: #cc3333;
    font-size: 15px !important;
    font-style: italic;
    padding: 0px 15px !important;
    margin-top: 0px !important;
    margin-bottom: 10px !important; }
  .wrap-event .wrap-info-event .desc-event {
    color: #676767;
    font-size: 17px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 24px !important;
    /* fallback */
    max-height: 120px;
    -webkit-line-clamp: 5;
    /* number of lines to show */
    -webkit-box-orient: vertical; }
  .wrap-info-event .arrowEvent {
    color: #cc3333;
    font-size: 15px !important; }
  .row-info-event {
    height: 320px !important; }
  .wrap-info-event .detail-event {
    font-size: 1.5rem !important;
    top: -9px !important;
    right: 15px !important; } }

/*Tablet nhỏ(480 x 640)*/
/*Iphone(480 x 640)*/
/*Smart phone nhỏ*/
