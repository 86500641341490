@charset "UTF-8";
html {
  font-size: 100%;
  scroll-behavior: smooth; }

body {
  font-family: "Segoe UI", sans-serif; }

.button--transparent {
  background: none;
  border: none; }
  .button--transparent:active, .button--transparent:hover, .button--transparent:focus {
    border: none;
    background: none;
    outline: none; }

.row--center {
  display: flex;
  justify-content: center;
  align-items: center; }

.mg--16 {
  margin: 0 16px; }

@media (min-width: 768px) and (max-width: 960px) {
  .page__icon-btn {
    width: 20px; } }

.page__text {
  font-size: 14px; }
  @media (min-width: 768px) and (max-width: 960px) {
    .page__text {
      font-size: 10px; } }

.nav-link {
  padding: 0px; }

#wrap-editor img {
  height: auto;
  margin: 0 auto; }

.wrap-page-intro-block h1 {
  font-family: "Segoe UI", sans-serif;
  color: #cc3333;
  padding-bottom: 0.7vw;
  font-size: 2.5vw; }

.wrap-page-intro-block p {
  font-family: "Segoe UI", sans-serif;
  color: #676767;
  font-size: 1.3vw; }

.text-up-shadow {
  font-weight: bold;
  text-shadow: 4px 4px 5px #000000;
  font-family: "Roboto", sans-serif; }

.btn-danger {
  text-transform: uppercase;
  font-weight: bold;
  box-shadow: 1px 2px 10px 0px #9c979c;
  border-color: #cc3333; }

.noti-modal,
.gcs-result-modal {
  height: auto;
  position: relative !important;
  padding-top: 10px; }

.gcs-header-text {
  font-family: arial, sans-serif !important; }

.wrap-gcs-item {
  margin: 10px 0px; }
  .wrap-gcs-item .gcs-item-a {
    text-decoration: underline !important;
    color: #0000ff !important;
    cursor: pointer;
    font-size: 18px;
    font-weight: normal; }
  .wrap-gcs-item .gcs-item-link {
    font-size: 15px;
    color: #009933;
    margin-bottom: 2px; }
  .wrap-gcs-item .gcs-item-wrap-desc {
    display: flex; }
    .wrap-gcs-item .gcs-item-wrap-desc .gcs-item-img {
      max-height: 120px;
      width: auto;
      margin-right: 10px; }
    .wrap-gcs-item .gcs-item-wrap-desc .gcs-item-desc {
      font-size: 15px;
      font-weight: normal; }

p.noti-modal-text {
  text-align: center;
  font-weight: normal; }

.fixed-font {
  font-family: Tahoma !important; }

.container-travel {
  padding-left: calc(100vw * 0.06);
  padding-right: calc(100vw * 0.06); }
  .container-travel #wrap-editor {
    margin-top: 1.5rem; }
    .container-travel #wrap-editor figure figcaption {
      text-align: center; }
    .container-travel #wrap-editor p {
      font-size: 1.3vw;
      text-align: justify; }
    .container-travel #wrap-editor iframe {
      max-width: 100%; }

.no-padding {
  padding: 0 !important; }

.no-padding-left {
  padding-left: 0 !important; }

.no-padding-right {
  padding-right: 0 !important; }

.row {
  margin-left: 0;
  margin-right: 0; }

.header {
  background-color: #ffffff; }
  .header .wrap-social-info {
    height: 32px;
    display: flex; }
    .header .wrap-social-info ul {
      list-style-type: none;
      overflow: hidden; }
    .header .wrap-social-info .wrap-left-header {
      height: 100%;
      width: 20%; }
      .header .wrap-social-info .wrap-left-header .icon-header {
        width: 25px;
        height: 25px; }
      .header .wrap-social-info .wrap-left-header .a-social {
        height: 100%; }
        .header .wrap-social-info .wrap-left-header .a-social img {
          height: inherit; }
    .header .wrap-social-info .wrap-right-header {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      height: 100%; }
      .header .wrap-social-info .wrap-right-header .wrap-gcs {
        display: flex; }
        .header .wrap-social-info .wrap-right-header .wrap-gcs input {
          border-top: 2px solid #fff;
          border-bottom: 2px solid #fff;
          border-left: 0.5px solid #c9c7c7;
          border-right: 0.5px solid #c9c7c7;
          height: 32px;
          width: 420px;
          max-width: 100%;
          font-size: small;
          padding: 5px 10px;
          font-style: italic;
          font-weight: 500; }
        .header .wrap-social-info .wrap-right-header .wrap-gcs input:hover,
        .header .wrap-social-info .wrap-right-header .wrap-gcs input:active,
        .header .wrap-social-info .wrap-right-header .wrap-gcs input:focus {
          outline-width: 0; }
      .header .wrap-social-info .wrap-right-header .email-contact {
        line-height: 32px;
        color: #676767;
        margin-left: 5px;
        font-size: 14px; }
      .header .wrap-social-info .wrap-right-header .wrap-icon-search {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        justify-items: center;
        background-color: #c9c7c7;
        cursor: pointer; }
        .header .wrap-social-info .wrap-right-header .wrap-icon-search .icon-search {
          color: #fff;
          width: 35px;
          height: 35px; }
  .header #navbarNavDropdown {
    height: inherit; }
    .header #navbarNavDropdown ul {
      height: inherit; }
      .header #navbarNavDropdown ul a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%; }
  .header .bg-light {
    background: transparent !important; }
  .header .wrap-menu {
    background: #06284c; }
    .header .wrap-menu .wrap-logo {
      display: none; }
  .header .wrap-transparent-menu {
    background: rgba(6, 40, 76, 0.5);
    position: absolute;
    width: 100%;
    z-index: 1; }
    .header .wrap-transparent-menu .wrap-btn-menu .btnToggle {
      color: "white";
      font-weight: "bold"; }
    .header .wrap-transparent-menu .wrap-logo {
      display: none; }
  .header .navbar-light .navbar-nav .show > .nav-link,
  .header .navbar-light .navbar-nav .active > .nav-link,
  .header .navbar-light .navbar-nav .nav-link.show,
  .header .navbar-light .navbar-nav .nav-link.active {
    color: #fff; }
  .header .navbar {
    padding: 0 !important; }
  .header .navbar-nav {
    width: 100%; }
  .header .nav-item {
    width: 25%;
    text-align: center; }
    .header .nav-item .nav-title {
      color: white;
      font-weight: bold;
      font-size: calc(100vw * 0.0135) !important; }
  .header .navbar-light .navbar-nav .nav-link {
    color: #fff;
    font-size: 18px; }
  .header .navbar-nav {
    line-height: calc(100vw * 0.093); }
  .header .img-social-fb {
    width: 7px; }
  .header .img-social-gp {
    width: 20px; }
  .header .wrap-btn-social {
    border: 1px solid #000;
    height: 35px;
    width: 35px;
    text-align: center;
    padding-top: 3px; }
  .header .wrap-col-2-head {
    justify-content: flex-end; }
  .header .wrap-search-head {
    position: relative; }
  .header .input-search {
    height: 25px;
    margin-top: 6px; }
  .header .dropdown-menu {
    margin-top: 0;
    padding: 0;
    width: 100%;
    border: none;
    max-height: 400px;
    overflow: auto !important;
    background: rgba(255, 255, 255, 0.94) !important; }
  .header .dropdown-item.active,
  .header .dropdown-item:active {
    background-color: rgba(255, 255, 255, 0.94) !important; }
  .header .dropdown-item {
    line-height: calc(100vw * 0.024);
    border-bottom: 1px solid #e7e7e7;
    color: #565656;
    font-size: calc(100vw * 0.01) !important;
    justify-content: start !important; }
  .header .dropdown-item:hover {
    color: #cc3333;
    font-weight: bold; }
  @media (min-width: 992px) {
    .header .nav-home-mobile {
      display: none; } }

.footer {
  background: #053a73;
  color: #000;
  padding-bottom: 30px; }
  .footer ul {
    list-style: none;
    padding-left: 0; }
    .footer ul li {
      padding-top: 3x;
      padding-bottom: 3px; }
  .footer .head-title-footer {
    font-size: calc(100vw * 0.0165) !important;
    padding-bottom: 15px;
    color: #e8e8e8;
    font-weight: bold; }
  .footer a {
    font-size: calc(100vw * 0.01) !important;
    color: #ededed; }
  .footer a:hover {
    color: #ededed; }
  .footer .name-company-footer {
    font-size: 20px;
    font-weight: bold; }
  .footer .icon-phone {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg); }
  .footer .row-contact {
    margin-top: 50px;
    margin-bottom: 5px; }
  .footer .zalo-footer,
  .footer .phone-footer,
  .footer .viber-footer,
  .footer .whatsapp-footer {
    margin-right: 10px; }
  .footer .email-company-footer {
    font-size: calc(100vw * 0.01) !important; }
  .footer .phone-footer {
    width: 25px;
    height: 25px; }
  .footer .phone-footer-number {
    font-size: calc(100vw * 0.01) !important; }
  .footer .zalo-footer,
  .footer .viber-footer,
  .footer .whatsapp-footer {
    width: 27px;
    height: 27px; }
  .footer .wrap-logo {
    margin: 0 auto;
    width: calc(100vw * 0.14);
    height: calc(100vw * 0.14)/2;
    margin-bottom: 8px; }
    .footer .wrap-logo .logo-footer {
      height: 100%;
      width: 100%; }
  .footer .wrap-link-footer {
    padding-top: 40px; }
  .footer .col-last {
    color: #d2d2d2; }
  .footer .btn-contact {
    color: #fff;
    box-shadow: 1px 2px 10px 0px #9c979c; }
  .footer .wrap-support .row .title {
    font-size: calc(100vw * 0.012); }
  .footer .wrap-support .btn-contact {
    padding: calc(100vw * 0.02 * 0.28) calc(100vw * 0.02) !important; }
    .footer .wrap-support .btn-contact span {
      font-size: calc(100vw * 0.012) !important; }
    .footer .wrap-support .btn-contact i {
      font-size: calc(100vw * 0.02) !important; }

@media screen and (min-width: 1700px) {
  li.logo img {
    width: calc(255px + calc(100vw - 1700px) * 0.02) !important; }
  .service-tour-info .wrap-service .wrap-ref-evt-btn a {
    width: auto; }
  .service-tour-info .wrap-service .wrap-ref-evt-btn a:hover {
    width: auto; } }

@media (min-width: 992px) {
  .dropdown .dropdown-menu {
    transition: all 0.3s;
    overflow: hidden;
    transform-origin: top center;
    transform: scale(1, 0);
    display: block;
    border-radius: 0; }
  .dropdown:hover .dropdown-menu {
    transform: scale(1); }
  .dropdown-toggle::after {
    display: none; } }

@media (max-width: 992px) {
  .header .nav-item {
    width: 100%;
    text-align: left; }
  .logo {
    display: none; }
  .container-menu {
    padding-left: 0;
    padding-right: 0; }
  #navbarNavDropdown {
    background: #06284c;
    padding-left: 20px;
    padding-right: 20px; }
  .nav-link {
    font-size: 14px !important; }
  .header .navbar-nav {
    line-height: 40px; }
  .header .navbar {
    height: auto; }
  .header .nav-item {
    border-bottom: 1px dotted #909090; }
    .header .nav-item .nav-title {
      font-size: 16px !important; }
  .header .wrap-google-custom-search {
    display: none; }
  .header .email-contact {
    display: none; }
  .header .a-social {
    margin: auto 0; }
    .header .a-social .social-img {
      height: 43px;
      margin-top: 0px; }
  .dropdown-menu {
    background: #1f1f1f;
    padding-left: 20px !important;
    padding-right: 20px !important;
    border-top: 1px dotted #909090 !important;
    transition: all 200ms ease-in; }
    .dropdown-menu .dropdown-item {
      border-bottom: 1px dotted #909090;
      color: rgba(255, 255, 255, 0.75); }
    .dropdown-menu .dropdown-item:hover {
      background: #1f1f1f;
      color: white; }
  .wrap-btn-menu {
    width: 100%;
    position: relative;
    line-height: 60px;
    padding-left: 15px;
    padding-right: 15px;
    min-height: 60px; }
  .navbar-toggler {
    position: absolute;
    right: 8px;
    top: 10px; }
  .dropdown-toggle::after {
    position: absolute;
    right: 15px;
    top: 29px;
    display: none; }
  .dropdown.show {
    border-bottom: none !important; }
  .footer .head-title-footer {
    text-align: center;
    font-size: 20px !important;
    padding-bottom: 15px;
    font-weight: bold; }
  .footer li {
    text-align: center; }
  .footer a {
    font-size: 13.6px !important;
    color: #ededed; }
  .footer .wrap-logo {
    margin: 0 auto;
    width: 200px;
    height: 100px; }
  .footer .email-company-footer {
    text-align: center;
    font-size: 13.6px !important; }
  .footer .row-contact {
    display: flex;
    justify-content: center; }
  .footer .phone-footer-number {
    text-align: center;
    font-size: 13.6px !important; }
  .footer .wrap-support .row .title {
    font-size: 13.6px; }
  .footer .wrap-support .btn-contact {
    margin-top: 20px;
    padding: 10px 35px !important; }
    .footer .wrap-support .btn-contact span {
      font-size: 16px !important; }
    .footer .wrap-support .btn-contact i {
      font-size: 36px !important; } }

/*Ipad ngang(1024 x 768)*/
@media screen and (max-width: 1024px) {
  .container-header {
    position: relative;
    height: 54px !important; }
    .container-header .wrap-google-custom-search .gsc-search-button {
      display: none; }
  .header .wrap-social-info .wrap-left-header {
    width: 50%; }
  .header .wrap-social-info .wrap-right-header {
    width: 50%; }
    .header .wrap-social-info .wrap-right-header .wrap-gcs {
      width: 100%; }
      .header .wrap-social-info .wrap-right-header .wrap-gcs input {
        width: 85%;
        border: 1px solid grey; } }

/*Ipad dọc(768 x 1024)*/
@media screen and (max-width: 768px) {
  .wrap-page-intro-block h1 {
    font-size: 34px; }
  .wrap-page-intro-block p {
    font-size: 17px; }
  .header .container-travel {
    padding-right: 0;
    width: 100%; }
  .header .wrap-menu,
  .header .wrap-transparent-menu {
    display: flex;
    justify-content: flex-end; }
    .header .wrap-menu .wrap-logo,
    .header .wrap-transparent-menu .wrap-logo {
      z-index: 2;
      display: block;
      position: absolute;
      left: 30px;
      top: 33px;
      width: fit-content;
      height: fit-content; }
      .header .wrap-menu .wrap-logo .logo-mobile,
      .header .wrap-transparent-menu .wrap-logo .logo-mobile {
        width: 150px;
        height: auto; }
    .header .wrap-menu .wrap-btn-menu,
    .header .wrap-transparent-menu .wrap-btn-menu {
      height: 78px; }
      .header .wrap-menu .wrap-btn-menu .navbar-toggler,
      .header .wrap-transparent-menu .wrap-btn-menu .navbar-toggler {
        position: absolute;
        right: 8px;
        top: 20px; }
  .header .wrap-transparent-menu {
    background: #06284c;
    position: unset; }
  .header .wrap-social-info .wrap-left-header {
    width: 40%; }
  .header .wrap-social-info .wrap-right-header {
    width: 60%; }
    .header .wrap-social-info .wrap-right-header .wrap-gcs {
      width: 100%; }
      .header .wrap-social-info .wrap-right-header .wrap-gcs input {
        width: 100%;
        border: 1px solid grey; }
      .header .wrap-social-info .wrap-right-header .wrap-gcs .wrap-icon-search {
        display: none; }
  .footer .wrap-logo {
    margin: 0 auto;
    width: 150px;
    height: 75px; }
  .footer .btn-contact-footer:hover {
    width: 60px !important; }
  .footer .wrap-support .container-travel .h1-tour {
    font-size: 40px; }
  .footer .wrap-support .container-travel .title {
    font-size: 17px; }
  .container-travel #wrap-editor p {
    font-size: 17px; } }

/*Tablet nhỏ(480 x 640)*/
@media screen and (max-width: 480px) {
  .header .container-header .row .wrap-google-custom-search {
    margin-top: 5px;
    position: absolute;
    right: -37px; }
    .header .container-header .row .wrap-google-custom-search .gsc-search-button {
      display: block;
      margin-top: 3px; }
  .header .container-header .row .email-contact {
    display: none; }
  .header .wrap-social-info .wrap-left-header {
    width: 31%; }
  .header .wrap-social-info .wrap-right-header {
    width: 69%; }
    .header .wrap-social-info .wrap-right-header .wrap-gcs {
      width: 100%; }
      .header .wrap-social-info .wrap-right-header .wrap-gcs input {
        width: 100%;
        border: 1px solid grey; }
      .header .wrap-social-info .wrap-right-header .wrap-gcs .wrap-icon-search {
        display: none; }
  .footer .wrap-btn-support {
    height: 100px; } }

/*Iphone(480 x 640)*/
