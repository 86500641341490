@font-face {
  font-family: 'HP DanTruong';
  src: url("/fonts/hp-dan-truong-heavy.ttf") format("truetype"); }

.wrap-not-found-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0; }
  .wrap-not-found-page .error-status-code {
    font-family: 'HP DanTruong';
    font-size: 200px;
    line-height: 200px; }
  .wrap-not-found-page .error-title {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 50px; }
  .wrap-not-found-page .error-description {
    font-family: 'Times New Roman', Times, serif;
    font-size: 25px; }
