@charset "UTF-8";
.carousel-slider img {
  height: 46vw;
  object-fit: cover; }

.carousel.carousel-slider .control-arrow {
  padding: 30px !important; }

.carousel .control-next.control-arrow:before {
  border-left: 15px solid #fff !important; }

.carousel .control-prev.control-arrow:before {
  border-right: 15px solid #fff !important; }

.carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
  border-top: 15px solid transparent !important;
  border-bottom: 15px solid transparent !important; }

.img-tour, .img-event {
  width: 100%;
  transition: transform 0.62s cubic-bezier(0.05, 0.2, 0.1, 1); }

.img-tour {
  height: 300px;
  object-fit: cover; }

.wrap-tour, .wrap-event-new {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  z-index: 99;
  margin-bottom: calc(100vw * 0.0055788); }

.wrap-event-new {
  box-shadow: 0px 1px 5px 0px #9c979c; }

.wrap-event-new:hover {
  box-shadow: 2px 2px 2px 2px #9c979c; }

.wrap-info-tour {
  position: absolute;
  bottom: 0;
  width: 100%;
  color: #fff;
  padding-bottom: calc(100vw*25/1434);
  text-shadow: 2px 2px 5px #000; }
  .wrap-info-tour .name-tour-relevant {
    text-transform: uppercase;
    font-size: calc(100vw * 0.0125); }

.detail-tour {
  font-size: 3rem;
  transition: all .2s ease;
  opacity: 0;
  position: absolute;
  right: 10px;
  -webkit-transition: 0.5s;
  transition: 0.5s; }

.detail-event {
  font-size: 1.5rem;
  transition: all .2s ease;
  opacity: 0;
  color: #cc3333;
  position: absolute;
  top: -9px;
  right: 15px;
  -webkit-transition: 0.5s;
  transition: 0.5s; }

.wrap-tour:hover .detail-tour, .wrap-event-new:hover .detail-event {
  opacity: 1; }

.wrap-tour:hover .img-tour {
  transform: scale(1.11); }

.wrap-tour:hover .tour-item-bg, .wrap-event-new:hover .event-item-bg {
  opacity: 0.5; }

.tour-item-bg, .event-item-bg {
  position: absolute;
  background: #000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.62s cubic-bezier(0.05, 0.2, 0.1, 1); }

.number-day {
  font-weight: bold;
  font-size: 20px; }

.name-tour {
  font-size: 20px;
  text-transform: uppercase; }

.new-tour {
  padding: 40px 0;
  background: #ededed; }

.new-event {
  background: #ededed;
  position: relative;
  padding-top: 40px; }
  .new-event .desc-event-home {
    color: #676767;
    font-size: 14px;
    overflow: hidden;
    display: -webkit-box;
    line-height: 24px;
    max-height: 96px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical; }
  .new-event .wrap-new-event .container-travel {
    padding-left: 8px;
    padding-right: 0px; }

.h1-tour {
  color: #cc3333;
  padding-bottom: calc(100vw*0.013947);
  font-size: calc(100vw*0.013947*2); }

.title {
  color: #676767;
  font-size: calc(100vw*0.01185495); }

.wrap-infor-more {
  height: 500px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center; }

.parallax-window-tour, .parallax-window-event {
  position: relative;
  min-height: 600px;
  background: transparent; }

.btn-more-tour, .btn-more-event {
  background: #cc3333 !important;
  border-radius: 5px !important;
  border: #cc3333;
  margin-top: 20px;
  box-shadow: 1px 2px 10px 0px #9c979c;
  padding: calc(100vw * 0.0069735) calc(100vw * 0.002440725) !important;
  font-size: calc(100vw * 0.012) !important;
  width: calc(100vw*0.13947); }
  .btn-more-tour span, .btn-more-event span {
    -webkit-transition: 0.5s;
    transition: 0.5s;
    color: white;
    font-weight: bold;
    text-transform: uppercase; }

.img-event {
  width: 100%; }

.wrap-info-event {
  background: #ededed;
  padding-top: 20px;
  padding-bottom: 20px; }

.category-event {
  transition: all .2s ease; }

.name-event {
  font-weight: bold;
  transition: all .2s ease; }

.location-event {
  margin-top: 20px;
  transition: all .2s ease; }

.detail-event {
  -webkit-transition: 0.5s;
  transition: 0.5s; }

.wrap-img .img-event {
  height: 100%;
  object-fit: cover; }

.wrap-img {
  position: relative;
  width: 100% !important;
  height: 100% !important; }

.wrap-img:after {
  content: '';
  display: block;
  padding-bottom: 100% !important;
  width: 100% !important; }

.content-xyz {
  position: absolute;
  width: 100% !important;
  height: 100% !important; }

.wrap-event-new:hover .img-event {
  -webkit-transform: scale(1.05);
  transform: scale(1.05); }

.event-item-img-date {
  position: absolute;
  top: 0;
  left: 0;
  width: 9.5vw;
  height: 9.5vw;
  background-color: #06284c;
  border-radius: 0 0 100% 0;
  padding-left: 8px;
  padding-top: 5px;
  color: #fff !important;
  z-index: 9999;
  line-height: 1.2; }
  .event-item-img-date .event-date {
    font-size: 2.7vw;
    font-weight: bold; }
  .event-item-img-date .event-month, .event-item-img-date .event-year {
    font-size: 1.8vw; }

.wrap-bgr-img {
  position: relative; }
  .wrap-bgr-img .bgr-btn {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3; }

.more-event {
  display: block;
  width: 100%;
  height: 32.6vw;
  z-index: 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center; }
  .more-event .overlay {
    width: 100%;
    height: 100%;
    background-color: transparent;
    transition: 1s;
    position: absolute;
    top: 0;
    z-index: 2; }
  .more-event .wrap-bgr-img-title {
    opacity: 0;
    transition: 2s left ease;
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    z-index: 3; }
    .more-event .wrap-bgr-img-title .bgr-img-title {
      padding: 5px 20px; }
    .more-event .wrap-bgr-img-title span, .more-event .wrap-bgr-img-title i {
      color: white;
      font-size: 2vw; }
  .more-event:hover .overlay {
    background-color: rgba(0, 0, 0, 0.7); }
  .more-event:hover .wrap-bgr-img-title {
    opacity: 1;
    left: 50%; }
  .more-event img {
    width: inherit;
    height: inherit;
    object-fit: cover; }

.btn-more-event {
  margin-top: 20px; }

.wrap-support {
  background: #ededed; }

.btn-contact {
  background: #cc3333 !important;
  border-radius: 5px !important;
  padding: 10px 35px !important; }
  .btn-contact span, .btn-contact i {
    font-weight: bold;
    color: white; }

.btn-contact-footer {
  top: 50%;
  position: absolute;
  transform: translateY(-50%); }
  .btn-contact-footer span {
    -webkit-transition: 0.5s;
    transition: 0.5s; }

.wrap-support {
  padding-top: 50px;
  padding-bottom: 50px; }

.auto-next-line-p {
  white-space: pre-line; }

.desc-event-main {
  padding-bottom: 40px; }

.icon-arrow-btn {
  position: absolute;
  top: 50%;
  left: 85%;
  font-size: 30px;
  opacity: 0;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  font-weight: bold;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: white; }

.btn-more-home {
  position: relative; }

.wrap-img-slide-home .btn-more-home span {
  font-size: calc(100vw*16/1434);
  display: inline-block;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s; }

.btn-more-home:hover span {
  padding-right: 25px; }

.btn-more-home:hover {
  width: 15vw !important; }

.btn-contact-footer:hover {
  width: calc(100vw*175/1434) !important; }

.btn-more-home:hover span:after {
  opacity: 3;
  right: 0; }

.btn-more-home:hover .icon-arrow-btn {
  opacity: 1; }

.carousel .control-dots .dot {
  width: 12px !important;
  height: 12px !important;
  opacity: 1 !important; }

.wrap-img-slide-home {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; }

.container-group-text-slide {
  position: absolute;
  width: 100%; }

.carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
  background: #cc3333; }

.btn-more-img-slide {
  margin-top: 20px !important;
  font-weight: bold !important; }

.txt-img-slide {
  color: #fff;
  font-size: calc(100vw * .04);
  font-weight: bold;
  text-shadow: 2px 2px 5px #bb3232;
  margin: 0px calc(100vw * 120 / 1434); }

.carousel.carousel-slider .control-arrow:hover {
  background: rgba(0, 0, 0, 0) !important; }

.HomePage .carousel .control-arrow, .HomePage .carousel.carousel-slider .control-arrow {
  opacity: 1 !important; }

.HomePage .carousel .control-arrow {
  margin-top: 10.8vw !important; }

.HomePage .carousel .control-prev.control-arrow:before {
  border-right: none !important;
  background-image: url("/images/arrow-left-slide.png");
  background-size: 30px 97px;
  display: inline-block;
  width: 50px;
  height: 168px;
  content: "";
  background-repeat: no-repeat; }

.HomePage .carousel .control-next.control-arrow:before {
  border-left: none !important;
  background-image: url("/images/arrow-right-slide.png");
  background-size: 30px 97px;
  display: inline-block;
  width: 50px;
  height: 168px;
  content: "";
  background-repeat: no-repeat; }

.HomePage .row-tour {
  background: #ededed;
  padding-left: calc(100vw * 0.0027894);
  padding-right: calc(100vw * 0.0027894); }
  .HomePage .row-tour .col-lg-3 {
    padding-left: calc(100vw * 0.0027894);
    padding-right: calc(100vw * 0.0027894); }

.HomePage .wrap-info-event .category-event {
  font-size: .95vw;
  color: #cc3333;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 0px; }

.HomePage .wrap-info-event .name-event {
  font-size: calc(100vw*24/1434);
  color: #06284c;
  line-height: calc(100vw*30/1434);
  margin-bottom: 0.2vw; }

.HomePage .wrap-info-event .location-event {
  margin-left: calc(100vw* -15/1434);
  color: #cc3333;
  font-size: 1.2vw;
  font-style: italic;
  padding: 0 calc(100vw*15/1434);
  margin-top: 0px;
  margin-bottom: 0.7vw; }

.HomePage .wrap-info-event .desc-event-home {
  color: #676767;
  font-size: 1.3vw;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 1.7vw;
  max-height: 10vw;
  -webkit-line-clamp: 5;
  /* number of lines to show */
  -webkit-box-orient: vertical; }

.HomePage .wrap-info-event .arrowEvent {
  color: #cc3333;
  font-size: calc(100vw*15/1434); }

.HomePage .row-info-event {
  height: calc(100vw*320/1434); }

.HomePage .detail-event {
  font-size: calc(100vw* 24 /1434) !important;
  transition: all 0.2s ease;
  opacity: 0;
  color: #cc3333;
  position: absolute;
  top: calc(100vw* -9/1434);
  right: calc(100vw*15/1434);
  -webkit-transition: 0.5s;
  transition: 0.5s; }

.HomePage .col-3-event {
  padding-left: 0;
  padding-right: calc(100vw*0.0055788); }

.HomePage .text-tour {
  text-shadow: 1px 1px 2px #000; }

.control-dots {
  padding-left: 0; }

.dropdown-menu::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background-color: #000; }

::-webkit-scrollbar {
  width: 6px;
  height: 0px;
  background-color: #fff; }

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #cc3333; }

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #4F5151;
  border-radius: 10px; }

.dropdown-menu::-webkit-scrollbar {
  width: 6px;
  height: 1px;
  background-color: #fff; }

.dropdown-menu::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #cc3333; }

.dropdown-menu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px; }

/*Ipad ngang(1024 x 768)*/
@media screen and (max-width: 1024px) {
  .btn-contact-footer {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%); }
  .wrap-tour .wrap-info-tour {
    padding-bottom: 10px !important; } }

/*Ipad dọc(768 x 1024)*/
@media screen and (max-width: 768px) {
  .wrap-bgr-img .bgr-btn {
    padding: 10px 35px !important;
    width: 200px; }
    .wrap-bgr-img .bgr-btn span {
      font-size: 16px; }
  .HomePage .carousel .control-arrow {
    margin-top: 0 !important;
    padding: 0 15px !important; }
    .HomePage .carousel .control-arrow::before {
      background-size: 30px 65px !important;
      width: 30px !important;
      height: 100px !important; }
  .more-event .wrap-bgr-img-title span, .more-event .wrap-bgr-img-title i {
    font-size: 20px; }
  .carousel-slider img {
    height: 100%;
    height: 300px !important; }
  .wrap-info-tour .name-tour-relevant {
    font-size: 18px; }
  .wrap-img-slide-home .txt-img-slide {
    font-size: 34px; }
  .HomePage .col-3-event {
    padding-left: 0;
    padding-right: 8px; }
  .footer .wrap-logo {
    width: 150px;
    height: 75px; }
  .new-tour .container-travel {
    padding-left: 20px;
    padding-right: 20px; }
  .new-tour .h1-tour {
    font-size: 40px; }
  .new-tour .title {
    font-size: 17px; }
  .row-tour {
    padding-left: 4px !important;
    padding-right: 4px !important; }
    .row-tour .home-4-tours {
      padding-left: 4px !important;
      padding-right: 4px !important; }
    .row-tour .wrap-tour {
      margin-bottom: 8px; }
      .row-tour .wrap-tour .wrap-info-tour {
        padding-bottom: 10px !important; }
      .row-tour .wrap-tour .img-tour {
        height: 235px; }
  .new-event .container-travel {
    padding-left: 20px;
    padding-right: 20px; }
    .new-event .container-travel .wrap-event-new {
      margin-bottom: 8px; }
      .new-event .container-travel .wrap-event-new .wrap-info-event .desc-event-home {
        color: #676767;
        font-size: 17px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-height: 24px !important;
        /* fallback */
        max-height: 120px;
        -webkit-line-clamp: 5;
        /* number of lines to show */
        -webkit-box-orient: vertical; }
      .new-event .container-travel .wrap-event-new .detail-event {
        font-size: 1.5rem !important;
        top: -9px !important;
        right: 15px !important; }
  .new-event .h1-tour {
    font-size: 40px; }
  .new-event .title {
    font-size: 17px; }
  .more-event {
    height: 302px; }
    .more-event .btn-more-event, .more-event .btn-more-home {
      width: 200px;
      padding: 10px 35px !important;
      border-color: #cc3333;
      border-radius: 5px !important;
      font-size: 16px !important; }
    .more-event .btn-more-event, .more-event .btn-more-home:hover {
      width: 220px !important;
      padding: 10px 35px !important;
      border-color: #cc3333;
      border-radius: 5px !important;
      font-size: 16px !important; }
  .btn-more-home:hover {
    width: 220px !important; }
  .btn-contact-footer {
    top: 40%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 147.64px; }
  .event-item-img-date {
    width: 120px;
    height: 120px;
    padding-left: 10px;
    line-height: 1.3; }
    .event-item-img-date .event-date {
      font-size: 30px; }
    .event-item-img-date .event-month, .event-item-img-date .event-year {
      font-size: 20px; }
  .HomePage .slide_image_home_page {
    height: 448px; } }

@media screen and (max-width: 425px) {
  .control-prev, .control-next {
    display: none; } }

.img-post-link {
  width: 100%; }

.App .backToTop {
  display: none;
  opacity: 0.3;
  position: fixed;
  bottom: 90px;
  right: 15px;
  z-index: 100;
  width: 32px;
  height: 32px;
  background: #999;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%; }
  .App .backToTop a img {
    width: 15px;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute; }
  .App .backToTop:hover {
    -webkit-animation: fa-spin 0.7s linear;
    background: #9B1D1D;
    opacity: 1;
    transition: all 1s; }
