.wrap-google-search-page {
  padding: 10px 30px;
  min-height: 1000px; }
  .wrap-google-search-page .wrapPage {
    margin-top: 20px; }
    .wrap-google-search-page .wrapPage a {
      display: inline-block; }
  .wrap-google-search-page .btnPage {
    margin-left: 5px; }
  .wrap-google-search-page .google-search-container {
    max-width: 1055px;
    margin: 30px auto; }
  .wrap-google-search-page .wrapSearch {
    display: flex; }
    .wrap-google-search-page .wrapSearch input {
      width: 100%;
      border: 1px solid #ddd; }
    .wrap-google-search-page .wrapSearch .wrap-icon-search {
      width: 40px;
      border: 1px solid #d1cdcd;
      cursor: pointer;
      justify-content: center;
      background-color: #c7c5c5; }
      .wrap-google-search-page .wrapSearch .wrap-icon-search .icon-search {
        width: 30px;
        height: 30px;
        margin-left: 4px;
        margin-top: 4px; }
  .wrap-google-search-page .underline {
    margin-top: 10px;
    border: 1px solid #add8e6; }
  .wrap-google-search-page .result {
    margin-top: 10px;
    font-size: 12px; }

@media (max-width: 540px) {
  .wrap-google-search-page img {
    max-width: 40vw;
    object-fit: contain; } }
