.wrap-post-social {
  cursor: pointer; }
  .wrap-post-social .custom-fb-btn {
    padding: calc(0.4vw);
    border-radius: 4px;
    background-color: #4267b2;
    display: inline-flex; }
    .wrap-post-social .custom-fb-btn .fb-btn-img {
      width: calc(1.5vw);
      height: calc(1.5vw);
      position: relative;
      border-radius: 4px;
      background-color: #fff; }
      .wrap-post-social .custom-fb-btn .fb-btn-img .fb-btn-img-txt {
        font-size: calc(1.1vw);
        font-weight: bolder;
        position: absolute;
        bottom: 0;
        right: calc(0.4vw);
        color: #4267b2; }
    .wrap-post-social .custom-fb-btn .fb-btn-txt {
      margin: auto 0 auto 8px;
      color: #fff;
      font-weight: bold;
      font-size: calc(.9vw); }
  .wrap-post-social .custom-fb-btn:hover {
    opacity: 0.6; }

@media screen and (max-width: 768px) {
  .wrap-post-social .custom-fb-btn {
    padding: 5px; }
    .wrap-post-social .custom-fb-btn .fb-btn-img {
      width: 16px;
      height: 16px; }
      .wrap-post-social .custom-fb-btn .fb-btn-img .fb-btn-img-txt {
        font-size: 11px; }
    .wrap-post-social .custom-fb-btn .fb-btn-txt {
      margin-left: 4px;
      font-size: 11px; } }
