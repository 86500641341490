.wrap-price {
  background: #eaeaea; }
  .wrap-price .custom-semantic-dropdown {
    margin-bottom: calc(100vw * 30 / 1434); }
  .wrap-price .wrap-checkbox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 41px !important;
    margin-bottom: calc(100vw * 30 / 1434 + 3px); }
    .wrap-price .wrap-checkbox .checkbox-label {
      margin-right: 30px; }
    .wrap-price .wrap-checkbox .checkbox {
      margin-left: 20px; }
  .wrap-price .title-center {
    text-align: center;
    color: #cc3333;
    font-size: 35px;
    font-weight: bold;
    margin-top: 60px; }
  .wrap-price .form-contact {
    margin: 0px 60px; }
  .wrap-price .title-form {
    margin-bottom: 10px; }
  .wrap-price .classValidate {
    margin-bottom: 30px;
    border: 2px solid #d93025;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.25rem; }
  .wrap-price .form-control {
    margin-bottom: 30px; }
  .wrap-price textarea {
    height: 300px !important; }
  .wrap-price .btn-send {
    padding: 15px 70px !important;
    margin-top: 20px;
    margin-bottom: 40px; }
  .wrap-price .demo {
    background: #e4e4e4;
    width: 70%;
    height: 500px; }
  .wrap-price .radio-price {
    margin-right: 5px; }
  .wrap-price .span-price {
    top: 2px;
    margin-right: 20px;
    position: relative; }
  .wrap-price .title-1 {
    margin-top: 50px; }
  .wrap-price .row-last {
    justify-content: content; }
  .wrap-price .captcha {
    justify-content: center;
    display: flex; }
  .wrap-price .container-travel .title-center {
    margin-top: 0; }
  .wrap-price .btn-price-request:hover {
    width: calc(100vw * 200 / 1434); }
  .wrap-price .btn-price-request:hover span:after {
    opacity: 3;
    right: 0; }
  .wrap-price .btn-price-request:hover .icon-arrow-btn {
    opacity: 1; }
  .wrap-price .btn-price-request {
    padding: calc(100vw * 0.006) calc(100vw * 0.02) !important; }
    .wrap-price .btn-price-request span {
      font-size: calc(100vw * 0.0111);
      transition: 0.5s;
      display: inline-block;
      position: relative; }
  .wrap-price .container-price .title-top-price {
    font-size: 2.51046vw;
    font-weight: 500;
    color: #cc3333; }
  .wrap-price .container-price .desc-contact-price {
    font-size: 1.3vw;
    color: #676767;
    margin: 0 75px;
    padding: 0;
    text-align: justify; }
  .wrap-price .container-price .title-input-price {
    font-size: 20px;
    color: #727272;
    margin-bottom: calc(100vw * 3 / 1434); }
  .wrap-price .container-price .form-input-price {
    box-shadow: none;
    border: none;
    font-size: calc(100vw * 14 / 1434);
    margin-bottom: calc(100vw * 30 / 1434); }
  .wrap-price .container-price .desc-title {
    padding-top: 2.7894vw;
    padding-bottom: 1.95258vw; }

@media (max-width: 768px) {
  .wrap-price {
    background: #eaeaea; }
    .wrap-price .container-travel .title-center {
      margin-top: 0; }
    .wrap-price .form-contact {
      margin: 0; }
    .wrap-price .container-price .title-top-price {
      font-size: 36px;
      margin: 40px 0 40px 0; }
    .wrap-price .container-price .desc-contact-price {
      margin: 0 calc(100vw * 30 / 1434) calc(100vw * 60 / 1434) calc(100vw * 30 / 1434);
      padding: unset;
      font-size: 17px; }
    .wrap-price .container-price .form-input-price {
      font-size: 14px; }
    .wrap-price .btn-price-request {
      width: 200px !important;
      padding: 10px 35px !important; }
    .wrap-price .btn-price-request span {
      font-size: 16px; }
  .wrap-price .container-price .btn-price-request:hover {
    width: 200px !important; }
  .wrap-price .container-price .icon-arrow-btn {
    font-size: 36px !important; } }

@media (max-width: 425px) {
  .wrap-price .container-price .desc-contact-price {
    margin: 0 calc(100vw * 50 / 1434) calc(100vw * 60 / 1434) calc(100vw * 50 / 1434); }
  .wrap-price .container-price .btn-price-request:hover {
    width: 200px !important; } }

@media (min-width: 425px) and (max-width: 767px) {
  .wrap-price .container-price {
    padding-left: unset;
    padding-right: unset; } }
