@charset "UTF-8";
.back-head-tour-info {
  padding-top: calc(100vw * 70 / 1434);
  padding-bottom: calc(100vw * 70 / 1434);
  height: 100%;
  text-align: center;
  color: #fff;
  width: 100%;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-transform: uppercase;
  box-shadow: 1px 2px 10px 0px #9c979c;
  padding-left: calc(100vw * 150 / 1434);
  padding-right: calc(100vw * 150 / 1434); }
  .back-head-tour-info .duration-tour {
    font-size: 2.51046vw; }
  .back-head-tour-info .h1-name-tour {
    font-size: 4vw;
    font-weight: bold; }
  .back-head-tour-info .tour-code {
    font-size: 2.51046vw; }

.location-tour {
  font-size: 22px; }

.wrap-body {
  padding-top: calc(100vw * 40 / 1434);
  padding-bottom: 0px; }

.col-6-info-tour .wrap-tour .name-more-tour {
  font-size: calc(100vw * 16/1434); }

.wrap-schedule-footer {
  display: flex;
  align-items: center;
  margin: 20px 0px; }
  .wrap-schedule-footer .wrap-post-views {
    background-color: #053a73;
    margin-right: 5px;
    border-radius: 4px;
    padding: 5px;
    text-align: center; }
    .wrap-schedule-footer .wrap-post-views p {
      margin: 0 5px;
      color: white;
      font-weight: 600;
      font-size: 13px; }

.tourInfo-title .back-head-tour-info .location-tour {
  font-size: 35px; }

.h3-tour-info {
  margin-bottom: calc(100vw * 20 / 1434);
  margin-left: calc(100vw * -15 / 1434);
  color: #cc3333;
  font-size: calc(100vw * 32 / 1434); }

.wrap-step-tour {
  border-left: 1px solid #4e4e4e;
  position: relative;
  padding-left: calc(100vw * 35 / 1434);
  padding-bottom: calc(100vw * 20 / 1434); }
  .wrap-step-tour .number-step {
    position: absolute;
    width: 2.8vw;
    height: 2.8vw;
    border-radius: calc(100vw * 20 / 1434);
    background: #cc3333;
    text-align: center;
    color: #fff;
    padding-top: calc(100vw * 7 / 1434);
    left: calc(100vw * -20 / 1434);
    font-weight: bold; }
    .wrap-step-tour .number-step .number-step-content {
      font-size: calc(100vw * 16 / 1434);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
  .wrap-step-tour .location-go {
    font-size: calc(100vw * 20 / 1434);
    margin-bottom: calc(100vw * 10 / 1434);
    padding-top: calc(100vw * 5 / 1434);
    color: #444; }
  .wrap-step-tour .location-desc {
    font-size: calc(100vw * 16 / 1434);
    width: 100%;
    white-space: pre-line;
    color: #767373; }

.wrap-slide {
  margin-top: 20px; }

.carousel .thumbs-wrapper {
  margin: 0; }

.thumbs {
  padding-left: 0; }

.carousel .thumb {
  width: 33.33%;
  margin-right: 3px;
  border: none;
  padding: 0;
  margin-top: 2px; }

.icon-schedule {
  width: 20px;
  margin-right: 5px; }

.service-tour-info {
  position: -webkit-sticky;
  position: sticky;
  top: 40px;
  margin-bottom: 5px; }
  .service-tour-info .wrap-service {
    -webkit-box-shadow: 1px 2px 10px 0px #9c979c;
    -moz-box-shadow: 1px 2px 10px 0px #9c979c;
    box-shadow: 1px 2px 10px 0px #9c979c;
    padding: 25px 20px;
    margin-top: calc(100vw * 40 / 1434); }
    .service-tour-info .wrap-service .wrap-ref-evt-btn {
      text-align: center;
      margin: 10px 0;
      display: flex;
      justify-content: center; }
      .service-tour-info .wrap-service .wrap-ref-evt-btn a {
        display: flex;
        max-width: 100%;
        width: 400px;
        transition: .5s; }
      .service-tour-info .wrap-service .wrap-ref-evt-btn i {
        display: none; }
      .service-tour-info .wrap-service .wrap-ref-evt-btn a:hover i {
        display: block; }
      .service-tour-info .wrap-service .wrap-ref-evt-btn a:hover {
        width: 500px; }
    .service-tour-info .wrap-service .wrap-ref-evt-btn a {
      justify-content: center;
      padding: 5px 25px !important; }
    .service-tour-info .wrap-service .wrap-ref-event {
      display: flex;
      justify-content: center;
      position: relative; }
    .service-tour-info .wrap-service .wrap-ref-event .desc-part {
      color: #acacac;
      font-weight: bold;
      font-size: 1.4vw;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%); }
    .service-tour-info .wrap-service .wrap-ref-event .price-part {
      color: #cc3333;
      font-size: 2.8vw; }
    .service-tour-info .wrap-service .wrap-ref-event .separate-part {
      color: #acacac;
      font-size: 2.6vw; }
    .service-tour-info .wrap-service .wrap-ref-event .unit-part {
      color: #acacac;
      font-size: 1.8vw; }
    .service-tour-info .wrap-service .title-serive {
      font-size: calc(100vw * 20 / 1434);
      color: #cc3333;
      margin-bottom: calc(100vw * 10 / 1434);
      font-weight: bold;
      margin-top: calc(100vw * 15 / 1434); }
    .service-tour-info .wrap-service .desc-serive {
      font-size: calc(100vw * 16 / 1434);
      margin-bottom: calc(100vw * 20 / 1434);
      white-space: pre-line;
      color: #767373; }
    .service-tour-info .wrap-service .btn-price:hover {
      width: 55% !important; }
    .service-tour-info .wrap-service .btn-request-price-tour {
      font-size: calc(100vw * 0.012); }

.wrap-note-schedule {
  margin-bottom: 20px;
  position: relative;
  padding-left: 2.4vw;
  text-align: justify; }
  .wrap-note-schedule .note-schedule {
    line-height: 1.4;
    color: #767373;
    padding: 0; }
  .wrap-note-schedule b {
    color: #444;
    left: -4vw;
    position: absolute; }
  .wrap-note-schedule b, .wrap-note-schedule i {
    font-size: calc(100vw * 16 / 1434); }

.btn-info-tour {
  padding: 15px 35px !important;
  background: #cc3333 !important;
  border-radius: 0 !important;
  margin-top: 25px;
  float: right; }
  .btn-info-tour .icon-arrow-btn {
    top: 5px; }

.img-tour {
  height: calc(100vw * 0.2430265 * 0.67431851); }

.wrap-more-tour {
  margin-top: 10px; }

.map-info {
  width: 100% !important;
  box-shadow: 1px 2px 10px 0px #9c979c; }

.wrap-tour-slide {
  margin-top: calc(100vw * 40 / 1434);
  box-shadow: 1px 2px 10px 0px #9c979c; }

.image-Slide-Tour {
  height: 100%;
  object-fit: cover; }
  .image-Slide-Tour img {
    height: calc(100vw * 400 / 1434);
    max-height: unset; }

.col-6-info-tour:first-child {
  padding-left: 10px;
  padding-right: 5px; }
  .col-6-info-tour:first-child .wrap-tour {
    margin-bottom: 10px; }

.col-6-info-tour:last-child {
  padding-left: 5px;
  padding-right: 10px; }
  .col-6-info-tour:last-child .wrap-tour {
    margin-bottom: 10px; }

/*Ipad ngang(1024 x 768)*/
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .wrap-note-schedule b {
    margin-top: 5px; } }

/*Ipad dọc(768 x 1024)*/
@media screen and (max-width: 768px) {
  .wrap-tour-info .wrap-post-social .social-btn {
    width: 40px;
    height: 40px;
    font-size: 20px; }
  .wrap-schedule-footer p {
    font-size: 14px; }
  .back-head-tour-info {
    padding: 70px 10px;
    height: 100%; }
    .back-head-tour-info .duration-tour {
      font-size: 36px !important; }
    .back-head-tour-info .h1-name-tour {
      font-size: 40px; }
    .back-head-tour-info .tour-code {
      font-size: 36px; }
  .img-tour {
    height: 100%; }
  .h3-tour-info {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: -15px;
    font-size: 32px; }
  .wrap-step-tour {
    padding-left: 25px;
    padding-bottom: 30px; }
    .wrap-step-tour .number-step {
      width: 30px;
      height: 30px;
      border-radius: 30px;
      padding-top: 7px;
      left: -15px; }
      .wrap-step-tour .number-step .number-step-content {
        font-size: 16px; }
    .wrap-step-tour .location-go {
      font-size: 16px;
      margin-bottom: 10px;
      padding-top: 0px; }
    .wrap-step-tour .location-desc {
      font-size: 14px; }
  .wrap-note-schedule {
    padding-left: 25px; }
    .wrap-note-schedule b {
      left: -45px;
      margin-top: 2px; }
    .wrap-note-schedule b, .wrap-note-schedule i {
      font-size: 14px; }
    .wrap-note-schedule .no-padding {
      font-size: 14px; }
  .service-tour-info .wrap-service {
    margin-top: 40px;
    margin-top: 40px; }
    .service-tour-info .wrap-service .wrap-ref-event .desc-part {
      font-size: 14px; }
    .service-tour-info .wrap-service .wrap-ref-event .price-part {
      font-size: 20px; }
    .service-tour-info .wrap-service .wrap-ref-event .unit-part {
      font-size: 10px; }
    .service-tour-info .wrap-service .title-serive {
      font-size: 16px;
      margin-bottom: 5px;
      margin-top: 0px; }
    .service-tour-info .wrap-service .desc-serive {
      font-size: 14px;
      margin-bottom: 20px; }
    .service-tour-info .wrap-service .btn-request-price-tour {
      font-size: 16px; }
    .service-tour-info .wrap-service .btn-price:hover {
      width: 100% !important; }
    .service-tour-info .wrap-service .btn-price:hover > .icon-arrow-btn {
      display: none; }
  .col-6-info-tour:first-child {
    padding-left: 10px;
    padding-right: 10px; }
    .col-6-info-tour:first-child .wrap-tour {
      margin-bottom: 10px; }
  .col-6-info-tour:last-child {
    padding-left: 10px;
    padding-right: 10px; }
    .col-6-info-tour:last-child .wrap-tour {
      margin-bottom: 10px; }
  .image-Slide-Tour {
    height: 100%;
    object-fit: cover; }
    .image-Slide-Tour img {
      height: 200px;
      max-height: unset; } }

/* Mobile */
@media screen and (max-width: 425px) {
  .service-tour-info .wrap-service .wrap-ref-event {
    justify-content: flex-end; } }

/*Iphone(480 x 640)*/
/*Smart phone nhỏ*/
