.image-head-tour {
  width: 100%;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative; }

.tour-header {
  background: #ededed;
  text-align: center;
  padding-top: calc(100vw * 0.027);
  color: #cc3333;
  padding-left: 0px !important;
  padding-right: 0px !important; }
  .tour-header .shadow-head {
    font-size: calc(100vw * 0.025); }
  .tour-header .des-list-tour {
    font-size: calc(100vw * 16 / 1434);
    color: #676767;
    padding: 0 calc(100vw * 90 / 1434); }

.wrap-list-tour {
  padding-top: calc(100vw * 0.0027894 * 2);
  padding-bottom: 0px; }
  .wrap-list-tour .container-travel {
    padding-left: 0;
    padding-right: 0; }
    .wrap-list-tour .container-travel .wrap-btn-support {
      margin-top: calc(100vw*0.041);
      margin-bottom: calc(100vw*0.047); }

.container-travel-tour {
  position: relative;
  top: -60px; }

.img-arrow-tour-left {
  width: 20px;
  height: 40px;
  margin-top: 5px;
  margin-right: 10px;
  cursor: pointer; }

.img-arrow-tour-right {
  width: 20px;
  height: 40px;
  margin-top: 5px;
  margin-left: 10px;
  cursor: pointer; }

.detail-tour {
  top: auto !important;
  bottom: -6px;
  color: #cc3333; }

.location-tour {
  font-weight: 500; }

.desc-tour {
  margin-bottom: 20px; }

.row-listTour .dd-wrapper {
  z-index: 100;
  display: none; }

.row-listTour .dd-header .fa {
  height: 40px;
  width: 54px;
  background: #cc3333;
  margin-right: 0 !important;
  border-radius: 3px; }

.row-listTour .fa-angle-down:after {
  content: url("/images/arrow-down.png");
  position: relative;
  top: 0px;
  left: 14px; }

.row-listTour .fa-angle-up:after {
  content: url("/images/arrow-up.png");
  position: relative;
  top: 0px;
  left: 14px; }

.row-listTour .dd-list-item {
  border-bottom: 1px solid #e7e7e7;
  color: #615757;
  font-weight: normal;
  padding: 15px !important;
  font-size: 1rem !important; }

.row-listTour .dd-header-title {
  color: #615757;
  font-size: 1rem !important;
  margin-right: 0 !important; }

.row-listTour .dd-list-item:hover {
  background: #8d1616 !important; }

.triangle {
  border-top: 0;
  border-right: calc(100vw * 0.00488145) solid transparent;
  border-bottom: calc(100vw * 0.00488145) solid;
  border-left: calc(100vw * 0.00488145) solid transparent;
  width: calc(100vw * 0.0069735);
  margin: 0 auto;
  color: #fff;
  transition: all 0.5s ease;
  opacity: 1;
  left: calc(50% - 5px);
  position: absolute !important;
  bottom: 0; }

.detail-tour {
  color: #fff; }

.wrap-tab-tour {
  margin-top: calc(100vw * 0.027) !important;
  overflow-x: auto;
  z-index: 101;
  background-color: #06284c; }
  .wrap-tab-tour .row-tab-tour {
    margin: 0 auto;
    display: flex;
    width: fit-content; }
  .wrap-tab-tour .tab-tour {
    text-align: center;
    padding-top: calc(100vw * 0.01) !important;
    padding-bottom: calc(100vw * 0.01) !important;
    position: relative;
    cursor: pointer;
    font-weight: 500;
    background: #06284c;
    color: rgba(255, 255, 255, 0.75);
    padding: 0 35px;
    font-size: calc(100vw * 0.01) !important; }
  .wrap-tab-tour .tab-tour:hover {
    color: #fff; }
  .wrap-tab-tour .tab-tour-active {
    text-align: center;
    padding-top: calc(100vw * 0.01) !important;
    padding-bottom: calc(100vw * 0.01) !important;
    position: relative;
    cursor: pointer;
    font-weight: 500;
    background: #cc3333;
    color: #fff;
    padding: 0 35px;
    min-width: 200px;
    font-size: calc(100vw * 18/1434) !important;
    font-weight: bold; }

.wrap-tab-tour:hover::-webkit-scrollbar {
  cursor: pointer;
  height: 20px;
  background-color: grey; }

.wrap-list-tour .img-tour {
  object-fit: cover;
  height: 100%; }

.row-listTour {
  padding-left: calc(100vw*0.0027894);
  padding-right: calc(100vw*0.0027894); }
  .row-listTour .item-child-col {
    padding-left: calc(100vw*0.0027894);
    padding-right: calc(100vw*0.0027894); }

.row-paging {
  justify-content: center; }

.icon-page {
  margin-right: 10px; }

.page {
  font-size: 20px;
  position: relative;
  top: -5px;
  font-weight: bold; }

.page-active {
  color: #cc3333 !important; }

.wrap-tour {
  height: calc(100vw * 0.2430265 * 0.67431851); }
  .wrap-tour .number-day {
    font-size: calc(100vw * 0.0125); }
  .wrap-tour .name-tour {
    font-size: calc(100vw * 0.0125); }
  .wrap-tour .detail-tour {
    font-size: calc(100vw * 0.03347); }

.btn-see-more-tour {
  padding: calc(100vw*0.006) calc(100vw*0.02) !important; }
  .btn-see-more-tour span {
    font-size: calc(100vw * 0.0111);
    -webkit-transition: 0.5s;
    transition: 0.5s; }
  .btn-see-more-tour .icon-arrow-btn {
    font-size: calc(100vw * 0.02);
    -webkit-transition: 0.5s;
    transition: 0.5s; }

.wrap-more-list-tour .span-more-list-tour {
  font-size: calc(100vw * 0.012); }

@media (max-width: 768px) {
  .row-tab-tour {
    display: none; }
  .row-listTour .col-md-3 {
    z-index: 50; }
  .row-listTour {
    width: 100%; }
  .row-listTour .dd-wrapper {
    width: 100%;
    margin-bottom: 8px;
    display: block;
    padding-left: 4px;
    padding-right: 4px; }
  .wrap-text-head {
    padding-top: 48px;
    padding-bottom: 40px; }
    .wrap-text-head .shadow-head {
      font-size: 40px;
      margin-bottom: 20px; }
    .wrap-text-head .des-list-tour {
      font-size: 16px;
      padding: 0 20px; }
  .wrap-tab-tour {
    margin-top: 0px;
    display: none; }
  .wrap-tour {
    height: 235px;
    margin-bottom: 8px; }
    .wrap-tour .number-day {
      font-size: 18px; }
    .wrap-tour .name-tour {
      font-size: 18px; }
    .wrap-tour .detail-tour {
      font-size: 3rem; }
  .btn-see-more-tour {
    padding: 10px 30px !important; }
    .btn-see-more-tour span {
      font-size: 1rem; }
    .btn-see-more-tour .icon-arrow-btn {
      font-size: 30px; }
  .btn-see-more-tour:hover {
    width: 184px !important; }
  .wrap-list-tour {
    padding-top: 8px; }
    .wrap-list-tour .row-listTour {
      padding-left: 4px;
      padding-right: 4px; }
      .wrap-list-tour .row-listTour .item-child-col {
        padding-left: 4px;
        padding-right: 4px; }
    .wrap-list-tour .container-travel {
      padding-left: 0;
      padding-right: 0; }
      .wrap-list-tour .container-travel .wrap-btn-support {
        margin-top: 20px;
        margin-bottom: 28px; }
  .wrap-more-list-tour .span-more-list-tour {
    font-size: 16px; } }
