@charset "UTF-8";
.dd-header-title {
  color: #615757;
  font-size: 1rem !important;
  margin: 0 auto;
  margin-left: calc(100vw*15/1434); }

.dd-list-item {
  border: 1px solid #e7e7e7;
  color: #615757;
  font-weight: normal;
  padding: calc(100vw * 15 / 1434);
  font-size: 1rem !important; }

.dd-list-item:hover {
  background: #8d1616 !important; }

.dd-wrapper .dd-list {
  z-index: 9999 !important;
  padding: 0 !important; }

.wrap-usas .image-head-usas {
  width: 100%;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

.wrap-usas .wrap-text-head {
  text-align: center;
  color: #fff;
  padding-top: 130px;
  padding-bottom: 130px; }

.wrap-usas .reset-a-parent {
  color: black;
  text-decoration: none; }

.wrap-usas .image-head-event {
  width: 100%;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

.wrap-usas .wrap-text-head {
  text-align: center; }

.wrap-usas .wrap-list-event {
  padding-bottom: 60px; }

.wrap-usas .container-travel-event {
  position: relative;
  top: -60px; }

.wrap-usas .shadow-head {
  text-shadow: 2px 2px 5px #000; }

.wrap-usas .detail-event {
  top: auto !important;
  bottom: -25px;
  color: #cc3333; }

.wrap-usas .location-event {
  font-weight: 500; }

.wrap-usas .desc-event {
  margin-bottom: 20px; }

.wrap-usas .image-head-event .title-usas {
  font-size: calc(100vw*36/1434);
  color: #cc3333; }

.wrap-usas .hoan-select {
  width: 200px !important; }

.wrap-usas .row-3 {
  position: relative;
  margin-top: calc(100vw*40/1434);
  margin-bottom: calc(100vw*40/1434); }

.wrap-usas .dd-wrapper {
  position: absolute !important;
  right: 0px;
  width: 100%;
  z-index: 800; }
  .wrap-usas .dd-wrapper .dd-list {
    max-height: 500px;
    overflow-y: auto; }

.wrap-usas .dd-wrapper .dd-header {
  border-radius: 0 !important; }

.wrap-usas .dd-header .fa {
  height: 40px;
  width: 54px;
  background: #cc3333;
  margin-right: 0 !important;
  border-radius: 3px; }

.wrap-usas .fa-angle-up:after {
  content: url("/images/arrow-up.png");
  position: relative;
  top: 0px;
  left: 14px; }

.wrap-usas .fa-angle-down:after {
  content: url("/images/arrow-down.png");
  position: relative;
  top: 0px;
  left: 14px; }

.wrap-usas .wrap-img {
  height: auto; }

.wrap-usas .name-usa-title {
  line-height: 22px;
  font-size: 22px; }

.wrap-usas .row-info-usa {
  margin-top: 10px; }

.wrap-usas .info-more-usa {
  margin-right: 20px;
  color: #8e8c8c; }

.wrap-usas .event-item-img-date {
  width: 70px;
  height: 70px;
  font-size: 12px; }

.wrap-usas .img-usa-1 {
  height: 150px;
  object-fit: cover; }

.wrap-usas .row-top, .wrap-usas .row-bottom, .wrap-usas .col-img-last {
  padding-left: 0;
  padding-right: 5px;
  position: relative;
  z-index: 99;
  text-align: center;
  height: 100%; }

.wrap-usas .col-img-first {
  padding-right: 5px !important; }

.wrap-usas .col-right {
  height: 100%; }

.wrap-usas .col-left {
  padding-left: 0;
  padding-right: 0px;
  position: relative;
  z-index: 99;
  text-align: center;
  height: 100%; }

.wrap-usas .text-item-usa {
  position: absolute;
  top: 50%;
  left: 0;
  color: white;
  transform: translateY(-50%);
  z-index: 100;
  width: calc(100% - 5px);
  text-shadow: 2px 2px 5px #000;
  padding: 0 15px; }

.wrap-usas .text-item-usa .bigTitle {
  font-size: 18px;
  line-height: 15px; }

.wrap-usas .text-item-usa .smallTitle {
  font-size: 15px;
  line-height: 13px; }

.wrap-usas .text-item-usa:hover {
  cursor: pointer; }

.wrap-usas .mask {
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  width: calc(100% - 5px);
  height: 100%;
  position: absolute;
  top: 0; }

.wrap-usas .mask1 {
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  width: calc(100% - 10px);
  height: 100%;
  position: absolute;
  top: 0; }

.wrap-usas .col-img-first img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  box-shadow: 1px 2px 10px 0px #9c979c; }

.wrap-usas .col-img-last img {
  box-shadow: 1px 2px 10px 0px #9c979c; }

.wrap-usas .small-img img {
  box-shadow: 1px 2px 10px 0px #9c979c; }

.wrap-usas .col-right img {
  box-shadow: 1px 2px 10px 0px #9c979c; }

.wrap-usas .row .col-img-first:hover .mask {
  opacity: 1;
  cursor: pointer; }

.wrap-usas .row-top:hover .mask {
  opacity: 1;
  cursor: pointer; }

.wrap-usas .row-bottom:hover .mask {
  opacity: 1;
  cursor: pointer; }

.wrap-usas .col-img-last:hover .mask1 {
  opacity: 1;
  cursor: pointer; }

.wrap-usas .col-right .col-img-last {
  padding-right: 5px !important; }

.wrap-usas .col-right {
  padding-left: 5px;
  padding-right: 0px; }

.wrap-usas .row-top {
  height: 230px;
  margin-bottom: 10px; }

.wrap-usas .row-bottom {
  height: 230px; }

.wrap-usas .row-2 {
  margin-top: 10px;
  height: 470px; }

.wrap-usas .btn-see-more-usas {
  margin-top: calc(100vw*60/1434);
  margin-bottom: calc(100vw*60/1434); }
  .wrap-usas .btn-see-more-usas .btn-danger {
    padding: calc(100vw*0.006) calc(100vw*0.02) !important; }
    .wrap-usas .btn-see-more-usas .btn-danger span {
      font-size: calc(100vw * 0.0111);
      -webkit-transition: 0.5s;
      transition: 0.5s; }
    .wrap-usas .btn-see-more-usas .btn-danger .icon-arrow-btn {
      font-size: calc(100vw * 0.02);
      -webkit-transition: 0.5s;
      transition: 0.5s; }

.wrap-usas .image-head-event .col-md-8 {
  padding-left: 0px; }

/*Ipad ngang(1024 x 768)*/
@media screen and (max-width: 1024px) {
  .wrap-usas .row-3 {
    margin-top: 40px;
    margin-bottom: 40px; } }

/*Ipad dọc(768 x 1024)*/
@media screen and (max-width: 768px) {
  .wrap-usas .container-travel {
    padding-left: 10px !important;
    padding-right: 5px !important; }
    .wrap-usas .container-travel .dd-wrapper {
      margin-top: unset;
      top: 5px !important;
      padding-left: 5px !important;
      padding-right: 5px !important; }
      .wrap-usas .container-travel .dd-wrapper .dd-list {
        max-height: 215px; }
  .wrap-usas .row-3 {
    margin-top: 40px;
    margin-bottom: 40px; }
  .wrap-usas .row-2 {
    height: 300px; }
  .wrap-usas .row-top {
    height: 145px; }
  .wrap-usas .row-bottom {
    height: 145px; }
  .wrap-usas .image-head-event .title-usas {
    font-size: 36px;
    color: #cc3333; }
  .wrap-usas .btn-see-more-usas {
    margin-top: 60px;
    margin-bottom: 60px; }
    .wrap-usas .btn-see-more-usas .btn-danger {
      padding: 10px 35px !important; }
      .wrap-usas .btn-see-more-usas .btn-danger span {
        font-size: 16px; }
      .wrap-usas .btn-see-more-usas .btn-danger .icon-arrow-btn {
        font-size: 30px; }
    .wrap-usas .btn-see-more-usas .btn-danger:hover {
      width: 184px !important; } }

/*Tablet nhỏ(480 x 640)*/
@media screen and (max-width: 480px) {
  .dd-header-title {
    font-size: 16px !important; }
  .dd-list-item {
    font-size: 16px !important; }
  .wrap-usas .container-travel {
    padding-left: 5px !important;
    padding-right: 5px !important; }
    .wrap-usas .container-travel .title-usas {
      text-align: center; }
    .wrap-usas .container-travel .dd-wrapper {
      padding-left: 5px !important;
      padding-right: 5px !important; }
  .wrap-usas .row-2 {
    height: unset; }
  .wrap-usas .row-top {
    height: unset; }
  .wrap-usas .row-bottom {
    height: unset; }
  .wrap-usas .img-usa-1 {
    height: unset; }
  .wrap-usas img {
    height: 235px; }
  .wrap-usas .col-img-first {
    margin-top: 15px;
    margin-bottom: 10px; }
  .wrap-usas .col-img-last {
    margin-top: 10px; }
  .wrap-usas .col-left {
    padding-left: 5px; } }

/*Iphone(480 x 640)*/
/*Smart phone nhỏ*/
