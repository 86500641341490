@charset "UTF-8";
.wrap-highlight {
  /*Horizontal Ipad(1024 x 768)*/
  /*Vertical Ipad(768 x 1024)*/
  /*Tablet nhỏ(480 x 640)*/
  /*Iphone*/
  /*Smartphone mini*/ }
  .wrap-highlight .image-head-highlight {
    width: 100%;
    object-fit: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }
  .wrap-highlight .wrap-text-head {
    text-align: center;
    color: #cc3333;
    margin-top: calc(100vw * 40 / 1434); }
  .wrap-highlight .shadow-head {
    font-size: calc(100vw * 36 / 1434); }
  .wrap-highlight .desc-highligth {
    background: #fff;
    margin: 0 auto;
    padding: 0px;
    word-break: break-word;
    white-space: pre-line;
    color: #636363;
    margin-bottom: 5px;
    font-size: 1.3vw; }
  .wrap-highlight .wrap-highlight-tour-slide .wrap-button-slide {
    display: flex;
    padding: 0;
    justify-content: center;
    align-items: center; }
    .wrap-highlight .wrap-highlight-tour-slide .wrap-button-slide .arrow-button-slide {
      width: 100%;
      height: 100%;
      background: none;
      border: none; }
    .wrap-highlight .wrap-highlight-tour-slide .wrap-button-slide :focus {
      outline: 0; }
    @media screen and (max-width: 580px) {
      .wrap-highlight .wrap-highlight-tour-slide .wrap-button-slide {
        display: none; } }
  .wrap-highlight .wrap-highlight-tour-slide .slide-content {
    text-align: center; }
    .wrap-highlight .wrap-highlight-tour-slide .slide-content .safe-image {
      max-width: 100%;
      max-height: 500px; }
  .wrap-highlight .carousel.carousel-slider .control-arrow:hover {
    background: rgba(0, 0, 0, 0); }
  .wrap-highlight .carousel-slider {
    overflow: inherit !important; }
  .wrap-highlight .control-next {
    right: -100px !important; }
  .wrap-highlight .control-prev {
    left: -100px !important; }
  .wrap-highlight .carousel .control-prev.control-arrow:before {
    content: url("/images/arrow-left.png");
    border: none !important; }
  .wrap-highlight .carousel .control-next.control-arrow:before {
    content: url("/images/arrow-right.png");
    border: none !important; }
  .wrap-highlight .control-arrow {
    height: 100%; }
  .wrap-highlight .carousel .slide {
    background: #fff; }
  .wrap-highlight .desc-hight-head {
    padding-bottom: 30px; }
  .wrap-highlight .wrap-thank {
    border-left: 1px solid #e7e7e7;
    padding: 15px;
    padding-top: 10px; }
  .wrap-highlight .formHighLight .slide-img {
    object-fit: cover; }
  .wrap-highlight .showThumbs-highlight .slide img {
    width: 99%;
    height: 500px;
    border: 1px solid #553838; }
  .wrap-highlight .wrap-thank-mobile {
    display: none; }
  @media screen and (max-width: 992px) {
    .wrap-highlight .wrap-thank-mobile {
      display: block;
      text-align: center; }
    .wrap-highlight .wrap-thank {
      display: none; }
    .wrap-highlight .wrap-list-highligth {
      padding-top: 0; }
    .wrap-highlight .shadow-head {
      font-size: 40px;
      margin-bottom: 1rem; }
    .wrap-highlight .wrap-thank-mobile p {
      margin-bottom: 0.5rem; }
    .wrap-highlight .wrap-thank-mobile p:last-child {
      margin-bottom: 1rem; } }
  @media screen and (max-width: 768px) {
    .wrap-highlight .wrap-text-head {
      margin-top: calc(100vw * 40 / 768);
      padding: 0; }
    .wrap-highlight .wrap-thank-mobile p, .wrap-highlight .desc-highligth {
      font-size: 16px; }
    .wrap-highlight .container-travel {
      padding: 0; } }
  @media screen and (max-width: 480px) {
    .wrap-highlight .wrap-text-head {
      margin-top: calc(100vw * 40 / 480); } }
  @media screen and (max-width: 320px) {
    .wrap-highlight .wrap-text-head {
      margin-top: calc(100vw * 40 / 320); } }
  @media screen and (max-width: 240px) {
    .wrap-highlight .wrap-text-head {
      margin-top: calc(100vw * 40 / 240); } }
