.wrap-contact {
  background: #eaeaea; }
  .wrap-contact .title-center {
    text-align: center;
    color: #cc3333;
    font-size: 35px;
    font-weight: bold;
    margin-top: 60px; }
  .wrap-contact .desc-contact {
    margin: 0px 40px 0 40px; }
  .wrap-contact .form-contact .captcha {
    justify-content: center;
    display: flex; }
  .wrap-contact .title-form {
    margin-bottom: 10px; }
  .wrap-contact .classValidate {
    margin-bottom: 30px;
    border: 2px solid #d93025;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.25rem; }
  .wrap-contact .form-control {
    margin-bottom: 30px; }
  .wrap-contact .btn-send {
    padding: 15px 70px !important;
    margin-top: 20px;
    margin-bottom: 40px; }
  .wrap-contact .demo {
    background: #e4e4e4;
    width: 70%;
    height: 500px; }
  .wrap-contact .body-img {
    height: 70%; }
  .wrap-contact .container-travel .title-center {
    margin-top: 0; }
  .wrap-contact .container-travel .form-contact {
    padding: 0 60px 0 60px; }
  .wrap-contact .btn-contact-request:hover {
    width: 184px; }
  .wrap-contact .btn-contact-request {
    padding: calc(100vw * 0.006) calc(100vw * 0.02) !important; }
    .wrap-contact .btn-contact-request span {
      font-size: calc(100vw * 0.0111);
      transition: 0.5s; }
  .wrap-contact .container-contact .title-top-contact {
    font-size: 2.51046vw;
    font-weight: 500;
    padding-top: 2.7894vw;
    color: #cc3333;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; }
  .wrap-contact .container-contact .desc-contact-text {
    font-size: 1.3vw;
    color: #676767;
    margin: 0 75px;
    padding: 0;
    padding-bottom: 2vw;
    text-align: justify; }
  .wrap-contact .container-contact .title-input-contact {
    font-size: 20px;
    color: #727272;
    margin-bottom: calc(100vw * 3 / 1434); }
  .wrap-contact .container-contact .form-input-contact {
    box-shadow: none;
    border: none;
    font-size: calc(100vw * 14 / 1434);
    margin-bottom: calc(100vw * 30 / 1434); }
  .wrap-contact .container-contact .textarea-input-contact {
    height: 250px; }
  .wrap-contact .container-contact .image-contact {
    width: 100%; }

@media (max-width: 768px) {
  .wrap-contact {
    background: #eaeaea; }
    .wrap-contact .container-travel {
      padding: 0px;
      padding-top: 2.7894vw; }
      .wrap-contact .container-travel .form-contact {
        padding: 0 calc(100vw * 50 / 1434) 0 calc(100vw * 50 / 1434); }
      .wrap-contact .container-travel .title-center {
        margin-top: 0;
        font-size: 40px;
        padding-top: 48px;
        margin-bottom: 40px; }
      .wrap-contact .container-travel .desc-contact-text {
        font-size: 17px;
        padding-bottom: 40px; }
      .wrap-contact .container-travel .form-input-contact {
        font-size: 14px; }
      .wrap-contact .container-travel .image-contact {
        position: sticky;
        top: 20px; }
      .wrap-contact .container-travel .btn-contact-request span {
        font-size: 16px; }
      .wrap-contact .container-travel .btn-contact-request {
        width: 200px !important;
        padding: 10px 35px !important; }
    .wrap-contact .form-contact {
      margin: 0; }
    .wrap-contact .desc-contact {
      margin: 0px 15px 20px 15px; }
  .wrap-contact .container-travel .btn-contact-request:hover {
    width: 200px !important; }
  .wrap-contact .btn-contact-request .icon-arrow-btn {
    font-size: 36px !important; } }

@media (max-width: 768px) {
  .wrap-contact .container-contact .image-contact {
    display: none; }
  .wrap-contact .container-contact .desc-contact-text {
    margin: 0 calc(calc(100vw * 50 / 1434) + 15px); } }

@media (max-width: 425px) {
  .wrap-contact .container-travel .btn-contact-request {
    width: 150px; }
  .wrap-contact .container-contact .desc-contact-text {
    margin-left: unset;
    margin-right: unset;
    margin: 0 15px; }
  .wrap-contact .container-contact .contact-col {
    padding: 0 15px; } }
